<template>
    <c-wizard-input-form v-model="dataset" :placeholder="T('Device Serial Number')" :index="index">
        <div class="instructions">
            <p>
                Tap the NFC tag shown below and enter the 7-digit alphanumeric code
                <span
                    class="nfc-note"
                >(note: code is case-sensitive)</span>
            </p>
            <p>
                <span>NFC</span>
            </p>
        </div>
    </c-wizard-input-form>
</template>

<script>
import cWizardInputForm from "./c-wizard-input-form";

export default {
    components: {
        cWizardInputForm
    },
    props: {
        value: {
            type: Object,
            required: true,
        },
        index: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
          dataset: Object.assign({}, this.value)
        };
    }
}
</script>

<style lang="scss" scoped>
.instructions {
    text-align: center;
    margin: auto;
    width: 70%;
    font-family: var(--font-brand-normal);
    font-size: 20px;
    p {
        padding: 1rem 1rem;
    }
    .nfc-note {
        font-size: 0.9rem;
        line-height: 4rem;
    }
}
</style>
