<template>
  <c-simple-dialog :id="id"
                   dialog-class="cpax-dose-event-editor"
                   :title="T('Dose Event Editor')"
                   :ok-title="T('Save')"
                   @cancel="onCancel"
                   @ok="onSave">
  <b-container class="cpax-dose-event-editor">
    <b-row class="form-row">
      <b-col>
        <span class="label">{{T('Description')}}</span>
      </b-col>
      <b-col>
        <b-form-textarea id="description"
                         v-model="description"
                         :placeholder="T('Description')"
                         rows="4" >
        </b-form-textarea>
      </b-col>
    </b-row>
    <b-row class="form-row">
      <b-col>
        <span class="label">{{T('Intake Date')}} </span>
      </b-col>
      <b-col>
        <c-datepicker id="dose-intake-date"
                      v-model="v$.intake.$model"
                      :state="vuelidateState(v$.intake)" />
      </b-col>
    </b-row>
    <b-row class="form-row">
      <b-col>
        <span class="label">{{ T('Intake Time')}}</span>
      </b-col>
      <b-col>
        <c-timepicker id="dose-intake-time"
                      v-model="v$.time.$model"
                      :state="vuelidateState(v$.time)" />
      </b-col>
    </b-row>
    <b-row class="form-row">
      <b-col>
        <span class="label">{{T('Intake Range')}}</span>
      </b-col>
      <b-col>
        <b-input-group :append="T('Minutes')">
          <b-form-input id="dose-range"
                        type="number"
                        number
                        :state="vuelidateState(v$.range)"
                        v-model="v$.range.$model"
          />
        </b-input-group>
      </b-col>
    </b-row>
  </b-container>
  </c-simple-dialog>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { vuelidateState } from "@/lib/vuelidate-helper";
import { required, minValue } from "@vuelidate/validators";

import { startOfDay, toTimePickerTime } from "@/lib/doses";
import moment from 'moment-timezone';

import cSimpleDialog from "./c-simple-dialog"
import cDatepicker from "./c-datepicker"
import cTimepicker from "./c-timepicker"

function Dose(v, tz) {
  if (v) {
    return {
      DoseID: v.ID,
      intake: moment(v.Intake).toDate(),
      range: v.Range,
      description: v.Comment,
      time: toTimePickerTime(v.Intake, tz)
    }
  }
  const now = new Date();
  return {
    DoseID: 0,
    intake: now,
    range: 0,
    description: "",
    time: toTimePickerTime(now, tz),
  };
}

export default {
  components: {
    cSimpleDialog,
    cDatepicker,
    cTimepicker,
  },
  setup() {
    return {
      v$: useVuelidate(),
      vuelidateState,
    };
  },
  props: {
    id: {
      type: String,
      default: 'dose-event-editor',
    },
    schedule: {
      type: Object,
      required: true,
    },
    timezone: {
      type: String,
      default: () => moment.tz.guess(),
      required: true,
    },
    dose: {
      type: Object,
      required: true,
    },
  },
  data() {
    return Dose(this.dose, this.timezone)
  },
  validations() {
    return {
      intake: {
        required,
        minValueOfSchedule: minValue(
          startOfDay(this.schedule.StartDate, this.timezone)
        )
      },
      time: {
        required,
      },
      range: {
        required,
      },
      description: {},
    }
  },
  methods: {
   onSave(evt) {
      evt.preventDefault();
      const vm = this;
      this.v$.$validate().then((v) => {
        if  (v) {
          let d = {
            ID: vm.DoseID,
            Intake: vm.intake,
            Range: vm.range,
            Comment: vm.description,
            Time: vm.time,
          };
          vm.$store.dispatch("doses/updateDose", d).then(() => {
            vm.$bvModal.hide(vm.id)
          }).catch((e) => {
            console.log(e)
            vm.notificationDialog(vm.T("Error"), vm.T("unable to update dose."))
          })
        }
      })
    },
    onCancel() {
      Object.assign(this, Dose(this.dose, this.timezone))
    }
  },
  watch: {
    dose: function(v) {
      Object.assign(this, Dose(v, this.timezone))
    }
  }
}

</script>

<style lang="scss" scoped>
 .form-row {
   margin-bottom: 20px;
 }
 .button-bar {
   display: flex;
   justify-content: space-between;
 }
</style>

<style lang="scss" >
 .cpax-dose-event-editor {
   label.form-control {
     &.is-invalid {
       background-image: unset;
     }
     &.is-valid {
       background-image: unset;
     }
   }
   .modal-footer {
     justify-content: space-between;
   }
 }

</style>
