<template>
  <b-container fluid>
    <b-row>
      <b-col>{{ T('Start Date') }}</b-col>
      <b-col>{{schedule['StartDate']}}</b-col>
    </b-row>
    <b-row>
      <b-col>{{ T('End Date') }}</b-col>
      <b-col>{{schedule['EndDate']}}</b-col>
    </b-row>
    <b-row>
      <b-col>{{ T('Schedule Type') }}</b-col>
      <b-col>{{  getText(scheduleTypeOptions, schedule['ScheduleType']) }}</b-col>
    </b-row>
    <b-row v-if="schedule['Technologies'] === 'eac'">
      <b-col>EAC Device ID</b-col>
      <b-col>{{schedule['EACCode']}}</b-col>
    </b-row>
    <b-row v-if="schedule['Technologies'] === 'nfc'">
      <b-col>NFC Unique ID</b-col>
      <b-col>{{schedule['NFCCode']}}</b-col>
    </b-row>
    <b-row>
      <b-col>Description</b-col>
      <b-col v-if="schedule['Description']">
        {{schedule['Description']}}
      </b-col>
    </b-row>
    <b-row>
      <b-col>Packaging</b-col>
      <b-col v-if="schedule['CardType']" >
        {{ getText(cardTypeOptions, schedule['CardType']) }}
      </b-col>
    </b-row>
    <b-row>
      <b-col>Blister Mapping</b-col>
      <b-col>
        <b-link  v-if="schedule['Card Type'] !== 'nocard'" v-b-modal.blister-mapping-review>
          {{ T('Click here to preview') }}
        </b-link>
      </b-col>
      <c-simple-dialog id="blister-mapping-review"
                       no-cancel-btn
                       :ok-title="T('Close')"
                       :title="T('Blister Mapping Review')" >
        <c-wizard-dose-grid :rows="7" :cols="4" readonly v-model="schedule" index="CardConfiguration" />
      </c-simple-dialog>
    </b-row>
    <b-row>
      <b-col>Video Guide</b-col>
      <b-col>
        <b-link v-if="schedule['videourl']" v-b-modal.video-guide-review>
          {{ T('Click here to preview') }}
        </b-link>
      </b-col>
        <c-simple-dialog id="video-guide-review"
                         no-cancel-btn
                         :ok-title="T('Close')"
                         :title="T('Video Guide Review')" >
          <c-wizard-input-form v-model="schedule"
                               readonly
                               :placeholder="T('Video URL')"
                               index="videourl" >
            <b-embed v-if="schedule['videourl'] && schedule['videourl'] != ''"
                     type="iframe"
                     aspect="16by9"
                     :src="videolink"
                     allowfullscreen>
            </b-embed>
          </c-wizard-input-form>
        </c-simple-dialog>
    </b-row>
  </b-container>
</template>

<script>
import cSimpleDialog from './c-simple-dialog';
import cWizardDoseGrid from "./c-wizard-dose-grid";
import cWizardInputForm from "./c-wizard-input-form";
import { convertToEmbeddedURL } from "@/lib/url-translator"

export default {
  components: {
    cSimpleDialog,
    cWizardDoseGrid,
    cWizardInputForm,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    technologyTypeOptions: {
      type: Array,
      required: true,
    },
    scheduleTypeOptions: {
      type: Array,
      required: true,
    },
    cardTypeOptions: {
      type: Array,
      required: true,
    },
    prnMonitoringPeriodOptions: {
      type: Array,
      required: true,
    },
    prnNotificationTypeOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      schedule: Object.assign({}, this.value),
    }
  },
  computed: {
    videolink() {
      return convertToEmbeddedURL(this.schedule['videourl']);
    }
  },
  methods: {
    getText(options, key) {
      const item = options.filter((i) => i.value === key)[0];
      console.log(item);
      return item ? item.text : key;
    }
  }
}
</script>
