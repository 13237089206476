i<template>
  <b-container>
    <b-row>
      <b-col>
        <c-notification-profile-listing :profiles="profiles"
                                        v-show="show('listing')"
                                        @view="view"
                                        @disable="disable"
                                        @create-new-profile="onCreateNewProfile"
        />
        <c-notification-edit v-show="show('edit')"
                             :notifications="editList"
                             :readonly="readonly"
                             @add-new-notification="onAddNewNotification"
                             @save="onSave"
                             @cancel="onCancel"
        />
      </b-col>
    </b-row>
  </b-container>

</template>

<script>
import cNotificationProfileListing from "./c-notification-profile-listing";
import cNotificationEdit from "./c-notification-edit";

import { mapState } from "vuex";


export default {
  components: {
    cNotificationProfileListing,
    cNotificationEdit,
  },
  data() {
    return {
      selectedSection: "listing",
      selectedProfile: "",
      editList: [],
      readonly: false,
    };
  },
  computed: {
    ...mapState('notification', ["profiles"]),
  },
  methods: {
    show(section) {
      return this.selectedSection === section;
    },
    view(name) {
      const p = this.profiles[name];
      console.log(name, p);
      if (p) {
        this.selectedProfile = name;
        this.readonly = p.Category !== 'custom';
        this.editList = [...p.Notifications];
        this.selectedSection = 'edit';
      }
    },
    disable(name) {
      const p = this.profiles[name];
      if (p) {
        const s = p.Enable;
        const np = Object.assign({}, p, {Enable: !s})
        this.$store.dispatch('notification/save', {name: name, profile: np});
        this.reset();
      }
    },
    reset() {
      this.editList = [];
      this.selectedProfile = '';
      this.selectedSection = 'listing';
    },
    onCancel() {
      this.reset();
    },
    onCreateNewProfile(name) {
      this.$store.dispatch('notification/add', name);
    },
    onAddNewNotification() {
      const l = this.editList.length;
      this.$set(this.editList, l, {Name: "", Delay: 0, Content: ''})
    },
    onSave(notifications) {
      if (this.selectedProfile) {
        const p = Object.assign({}, this.profiles[this.selectedProfile], {Notifications: notifications});
        this.$store.dispatch('notification/save', {name: this.selectedProfile, profile: p});
        this.reset();
      }
    }
  },
  mounted: function() {
    this.$store.dispatch('notification/get')
  }

}
</script>
