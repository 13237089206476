<template>
  <c-form-dialog
    :id="id"
    :title="T('Change Password')"
    @ok="onUpdate"
    @cancel="onCancel"
    :ok-text="T('Change Password')">

    <b-container>
      <b-row>
        <b-col>
          <b-form-group
            :label="T('Current password')"
            label-for="password">
            <b-input-group>
              <b-form-input id="password"
                            type="password"
                            :state="v$.password.$dirty ? !v$.password.$error : null"
                            v-model="v$.password.$model" />
            </b-input-group>
          </b-form-group>
          <b-form-group
            :label="T('New password')"
            label-for="newPassword">
            <b-input-group>
              <b-form-input id="newPassword"
                            type="password"
                            :state="v$.newPassword.$dirty ? !v$.newPassword.$error : null"
                            v-model="v$.newPassword.$model" />
            </b-input-group>
          </b-form-group>
          <b-form-group
            :label="T('Confirm your password')"
            label-for="confirmPassword">
            <b-input-group>
              <b-form-input id="confirmPassword"
                            type="password"
                            :state="v$.confirmPassword.$dirty ? !v$.confirmPassword.$error : null"
                            v-model="v$.confirmPassword.$model" />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
  </c-form-dialog>
</template>

<script>
import { mapActions } from "vuex";

import useVuelidate from "@vuelidate/core";
import { required, sameAs } from '@vuelidate/validators';
import cFormDialog from './c-form-dialog';

export default {
  setup: () => ({ v$: useVuelidate() }),
  components: { cFormDialog },
  props: {
    id: {
      type: String,
      default: "cChangePasswordDialog",
    }
  },
  data() {
    return {
      password: '',
      newPassword: '',
      confirmPassword: '',
    };
  },
  validations() {
    return {
      password: { required },
      newPassword: { required },
      confirmPassword: { match: sameAs(this.newPassword) },
    };
  },
  methods: {
    ...mapActions(["logout"]),
    resetForm() {
      this.password = '';
      this.newPassword = "";
      this.confirmPassword = "";
      this.v$.$reset();
    },
    onUpdate(evt) {
      evt.preventDefault();
      const isValidInput = this.v$.$validate();
      if (isValidInput) {
        let _vm = this;
        this.$store.dispatch('changePassword',
                             { oldPassword: this.password,
                               newPassword: this.newPassword })
            .then(() => {
              _vm.notificationDialog(
                _vm.T('Success!'),
                _vm.T('Your password has been successfully changed.'),
                { okTitle: _vm.T('Go to Login Page') }
              ).then(() => {
                _vm.resetForm();
                _vm.$bvModal.hide(this.id);
                _vm.logout();
                _vm.$router.push({ name: "login" });
              });
            });
      }
    },
    onCancel() {
      this.resetForm();
      this.$bvModal.hide(this.id);
    }
  },
}
</script>
