<template>
  <div class="cpax-side-panel-menu" >
    <div class="title">{{ title }}</div>
    <ul class="menu" >
      <li v-for='item in menuItems'
          :key='item.$id'
          @click="onSelect(item)"
          class='item'
          :class="{ selected: item.selected }">

        <span class='label'>{{ item.label }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      required: true,
      type: String,
    },
    items: {
      required: true,
      type: Array,
    },
  },
  data() {
    let i = 0;
    let l = this.items.map(x => Object.assign({selected: false, $id: i++}, x));

    return {
      selectedIdx: 0,
      menuItems: l,
    }
  },
  methods: {
    onSelect: function(item) {
      console.log(this.menuItems)
      const oldItem = this.menuItems[this.selectedIdx];
      if (oldItem)
        oldItem.selected = false;

      const idx = this.menuItems.indexOf(item);
      const newItem = this.menuItems[idx];
      console.log("onSelect", newItem, idx);
      this.$set(newItem, "selected", true);

      this.selectedIdx = idx;
      this.$emit('change', {new: newItem, old: oldItem});
    }
  },
  mounted: function() {
    const item = this.menuItems[this.selectedIdx];
    this.onSelect(item);
  },
  watch: {
    items: function() {
      this.onSelect(this.menuItems[0])
    }
  }
}
</script>

<style lang="scss" scoped>
 .cpax-side-panel-menu {
   padding: 10px;
   .menu {
     list-style-type: none;
     padding: 10px;
     margin: 0;
     li {
       &.item {
         color: white;
       }
       &:hover {
         cursor: pointer;
       }
       &.selected {
         font-weight: bold;
         color: var(--primary);
         &::before {
           content: '\2759';
           color: var(--primary);
           padding-right: 10px;
         }
       }
     }
   }
   font-family: var(--font-brand-normal);
   .title {
     whitespace: nowrap;
     color: white;
     font-size: 25px;
     font-weight: bold;
   }
   .label {
     font-size: 16px;
   }
 }
</style>
