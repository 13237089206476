<template>
  <b-container class="cpax-basic-information-form">
    <b-row class="form-row">
      <b-col>
        <span class="label">{{ T('Starting Date') }}</span>
      </b-col>
      <b-col>
        <b-form-datepicker id="start-date"
                           v-model="v$.startDate.$model"
                           :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                           value-as-date
                           @input="update"
                           :state="vuelidateState(v$.startDate)"
                           local="en">
        </b-form-datepicker>
      </b-col>
    </b-row>
    <b-row class="form-row">
      <b-col>
        <span class="label">{{ T('Ending Date') }}</span>
      </b-col>
      <b-col>
        <b-form-datepicker id="end-date"
                           v-model="v$.endDate.$model"
                           :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                           value-as-date
                           @input="update"
                           :state="vuelidateState(v$.endDate)"
                           local="en">
        </b-form-datepicker>
      </b-col>
    </b-row>
    <b-row class="form-row">
      <b-col>
        <span class="label">{{ T('Description') }}</span>
      </b-col>
      <b-col>
        <b-form-textarea id="description" v-model="description"
                         :placeholder="T('Description')"
                         @change="update"
                         rows="4">
        </b-form-textarea>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { startOfDay } from '@/lib/doses';
import { required, minValue } from "@vuelidate/validators";

import useVuelidate from "@vuelidate/core";
import { vuelidateState } from "@/lib/vuelidate-helper";

export default {
  setup: () => ({
    v$: useVuelidate(),
    vuelidateState,
  }),
  props: {
    value: {
      type: Object,
      required: true,
    },

    timezone: {
      type: String,
      default: undefined,
    },
  },
  data() {
    const today = startOfDay(new Date(), this.timezone).toDate();
    return {
      startDate: today,
      endDate: today,
      description: '',
    }
  },
  validations() {
    return {
      startDate: {
        required,
        minValueOfToday: minValue(startOfDay(new Date(), this.timezone).toDate())
      },
      endDate: {
        required,
        minValueOfStartDate: minValue(this.startDate),
      }
    }
  },
  computed: {
    today() {
      return startOfDay(new Date(), this.timezone)}
  },
  methods: {
    update() {
      this.v$.$validate().then((v) => {
        if (v) {
          const s = {
            StartDate: this.startDate,
            EndDate: this.endDate,
            Description: this.description
          };
          const r = Object.assign({}, this.value, s);
          console.log(r)
          this.$emit('input', r);
        }
      });
    },
    init(v) {
      if (v['StartDate']) {
        this.v$.startDate.$touch()
        this.startDate = v['StartDate'];
      }

      if (v['EndDate']) {
        this.v$.endDate.$touch()
        this.endDate = v['EndDate'];
      }

      if (v['Description'])
        this.description = v['Description'];

      this.$emit('input', Object.assign({}, this.value));
    }
  },
  mounted() {
    this.init(this.value);
    console.log(this.value);
  },
}
</script>

<style lang="scss" scoped>
 .form-row {
   margin-bottom: 20px;
 }
</style>


<style lang="scss">
 .cpax-basic-information-form {
   label.form-control {
     &.is-invalid {
       background-image: unset;
     }
     &.is-valid {
       background-image: unset;
     }
   }
 }
</style>
