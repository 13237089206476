<template>
  <c-simple-dialog dialog-class="cpax-schedule-notification"
                   :id="id"
                   :title="T('Schedule notification')"
                   :ok-title="T('Save')"
                   @ok="onSave">
    <b-container class="cpax-schedule-notification-form">
      <b-row class="form-row">
        <b-col>
          <b-form-group label-for="patient-notification-message"
                      :label="T('Notification Profile')" >
          <b-form-select v-model="notificationProfile"
                         :options="profileOptions" />
        </b-form-group>

        </b-col>
      </b-row>
    </b-container>
  </c-simple-dialog>

</template>

<script>
import { mapState } from 'vuex';
import cSimpleDialog from "./c-simple-dialog"

const PATIENT_DEFAULT = "Patient Default";

function useDefaultNotificationIfScheduleIsBlank(schedule) {
   return schedule.NotificationProfile ? schedule.NotificationProfile.Name : PATIENT_DEFAULT;
}

export default {
  components: {
    cSimpleDialog,
  },
  props: {
    id: {
      type: String,
      default: "cpax-schedule-notification"
    },
    schedule: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      notificationProfile: PATIENT_DEFAULT,
    }
  },
  computed: {
    ...mapState('notification', ['profiles']),
    profileOptions() {
      let options = Object.keys(this.profiles);
      options.push(PATIENT_DEFAULT);
      return options;
    }
  },
  methods: {
    onSave() {
      const p = this.profiles[this.notificationProfile]
      this.$emit('save', p);
    }
  },
  watch: {
    schedule: function(v) {
      this.notificationProfile = useDefaultNotificationIfScheduleIsBlank(v);
    }
  },
  mounted() {
    this.$store.dispatch('notification/get').then(() => {
      this.notificationProfile = useDefaultNotificationIfScheduleIsBlank(this.schedule);
    });
  }
}
</script>
