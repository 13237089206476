<template>
  <c-simple-dialog :id="id" :title="T('Add/Modify Video Instruction')" :ok-title="T('Save')" @ok="onUpdate" @cancel="onCancel" >
    <c-wizard-input-form v-model="dataset"
                         :placeholder="T('Video index')"
                         index='VideoURLs' >
        <b-embed v-if="dataset && dataset['VideoURLs'] && dataset['VideoURLs'] != ''"
                 type="iframe"
                 aspect="16by9"
                 :src="videolink"
                 allowfullscreen>
        </b-embed>
      </c-wizard-input-form>
  </c-simple-dialog>
</template>

<script>
import cSimpleDialog from "./c-simple-dialog";
import cWizardInputForm from "./c-wizard-input-form";
import useVuelidate from "@vuelidate/core";

import { convertToEmbeddedURL } from "@/lib/url-translator"

export default {
  setup: () => ({
    v$: useVuelidate(),
  }),
  components: {
    cSimpleDialog,
    cWizardInputForm,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      default: "",
    }
  },
  data() {
    return {
      dataset: { VideoURLs: this.url }
    }
  },
  computed: {
    videolink() {
        return convertToEmbeddedURL(this.dataset.VideoURLs);
    },
  },
  methods: {
    onUpdate: function() {
        this.$emit('update', this.dataset.VideoURLs)      //get the schedule and update it
    },
    onCancel: function() {
        console.log(this.dataset)
          this.dataset.VideoURLs= this.url;
    }
  },
  watch: {
      url: function(url) {
          this.dataset.VideoURLs = url;
      }
  }
}
</script>
