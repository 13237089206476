<template>
  <b-container fluid>
    <b-row align-v="center" v-if="$slots.default">
      <b-col align-self="center" class="content">
      <slot></slot>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-input v-model="v$.inputValue.$model"
                 :readonly="readonly"
                 :placeholder="placeholder"
                 :state="vuelidateState(v$.inputValue)"
                 :invalid-feedback="T(v$.inputValue.$error[0])"
                 @update="update" />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { vuelidateState } from "@/lib/vuelidate-helper";
import { required } from "@vuelidate/validators";

export default {
  setup: () => ({
    v$: useVuelidate(),
    vuelidateState,
  }),
  props: {
    value: {
      type: Object,
      required: true,
    },
    index: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: false,
    },
    rules: {
      type: Object,
      default: () => ({ required }),
    },
  },
  data() {
    return {
      inputValue: '',
    };
  },
  validations() {
    return {
      inputValue: this.rules,
    }
  },
  methods: {
    update: function() {
      this.v$.$validate().then((v) => {
        if (v) {
          let s = {};
          s[this.index] = this.inputValue;
          this.$emit('input', Object.assign({}, this.value, s))
        }
      });
    }
  },
  mounted() {
    this.inputValue = this.index === '' ? this.value : this.value[this.index];
  },
}
</script>

<style lang="scss" scope>
 .content{
   padding-bottom: 30px;
 }
</style>
