<template>
  <div>
        <b-table responsive striped :items="items" table-class="patients" primary-key="ID" :per-page="perPage"
          sort-icon-left small
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="currentPage" :fields="fields" @head-clicked="onHeaderClick">

            <template #head()="scope">
                <b-container v-show="searchFilter.key != scope.column">
                  <b-row class="justify-content-between">
                    <div>{{scope.label}}</div>
                    <div><font-awesome-icon :icon="['fas', 'sort']" /></div>
                  </b-row>
                </b-container>
                <div v-show="searchFilter.key == scope.column">
                    <b-form-input v-model="searchFilter.value" placeholder="Enter search string"></b-form-input>
                </div>
            </template>

            <template #cell(name)="data">
                <router-link class="patient-name" v-bind:to="{ name: 'patient', params: { pid: data.item.ID } }">
                    {{ data.value | defaultValue('(n/a)') }}
                </router-link>
            </template>
            <template #cell(Watch)="data">
                <b-form-checkbox v-model="data.item.Watch" switch @change="onWatchValueChanged(data.item)">
                </b-form-checkbox>
            </template>
            <template #cell(score)="data">
                <b-progress class="w-100" :value="data.item.score" :variant="variant(data.item.score)" />
            </template>
        </b-table>
        <b-container fluid class="pagination justify-content-center">
          <b-row>
            <b-col>
              <b-pagination class v-model="currentPage" :total-rows="rows" :per-page="perPage">
              </b-pagination>
            </b-col>
          </b-row>
        </b-container>
  </div>
</template>

<script>
import moment from "moment";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSort } from '@fortawesome/free-solid-svg-icons';
library.add(faSort);

export default {
  props: {
    patients: Array,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 20,
      softBy: "Name",
      sortDesc: false,
      fields: [
        {
          key: "Watch",
          sortable: true
        },
        {
          key: "Name",
          label: this.T("Participant Reference"),
          sortable: true,
          filter: true
        },
        {
          key: "score",
          label: this.T("Total adherence score"),
          sortable: true,
          filter: true
        },
        {
          key: "StartDate",
          label: this.T("Start at"),
          sortable: true,
          filter: true
        },
        {
          key: "EndDate",
          label: this.T("End at"),
          sortable: true,
          filter: true
        },
        {
          key: "device",
          sortable: true,
          filter: true
        },
        {
          key: "status",
          sortable: true,
          variant: 'gray',
          filter: true
        },
        {
          key: "period_score",
          label: this.T("Adherence score for period"),
          sortable: true,
          variant: 'gray',
        }
      ],
      searchFilter: {key: "", value: ""},
    };
  },
  computed: {
    dataset() {
      if (this.patients) {
        return this.patients.flatMap(x => Object.assign({}, x,
                                                       {StartDate: moment(x.StartDate),
                                                        EndDate: x.EndDate ? moment(x.EndDate) : moment(x.StartDate).add(Math.floor(Math.random() * 100), "day"),
                                                        Watch: x.Watch || false,
                                                        device: x.Device || "none",
                                                        status: x.Status || false,
                                                        score: x.Score || 0,
                                                        period_score: x.PeriodScore || "N/A" }));
      }
      return []
    },
    rows() {
      return this.patients.length;
    },
    items () {
      if (this.searchFilter.value.length > 0) {
        let ss = this.searchFilter.value.toLowerCase()
        return this.dataset.filter(x => {
          let v = x[this.searchFilter.key].toString() || "";
          return v.toLowerCase().search(ss) > -1
        })
      }
      return this.dataset
    },

  },
  methods: {
    onWatchValueChanged: function(evt) {
        console.log(evt);
        this.$emit('change', evt);
    },
    onHeaderClick: function(key, field) {
      if (field.filter) {
        this.searchFilter = {key: key, value: ""}
      }
    },
    variant: function(v) {
      if (v > 80) {
        return "primary"
      }
      if (v > 60) {
        return "success"
      }
      if (v > 50) {
        return "info"
      }
      return "danger"
    }
  }
}
</script>

<style lang="scss" >

 .b-table.patients {
   background-color: white;
   .table-gray {
     background-color: #f9f8f8;
   }
   .b-table-sticky-header {
     max-height: 700px;
   }
   a {
    color: var(--primary);
    &:hover, &:active {
      color: var(--secondary);
      text-decoration: none;
    }
   }
 }
</style>
