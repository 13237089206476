<template>
  <b-container fluid>
    <b-row>
      <b-col class="list">
        <b-table
          :fields="fields"
          :items="items"
          table-class="schedule-list"
          responsive
          striped
          borderless
          hover
          :tbody-tr-class="selectedItem"
          @row-clicked="onRowClick"
        ></b-table>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  props: {
    dataset: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      fields: [
        {
          key: "CardID",
          sortable: true,
          label: "Serial Number",
        },
        {
          key: "CardType",
          sortable: true,
          label: "Device Type",
        },
        {
          key: "Description",
          sortable: true,
          label: "Schedule Name",
        },
        {
          key: "StartDate",
          sortable: true,
          label: "Start Date",
        },
        {
          key: "EndDate",
          sortable: true,
          label: "End Date",
        },
        {
          key: "Score",
          sortable: true,
          label: "Adherence Score",
        },
      ],
    };
  },
  computed: {
    items() {
      if (this.dataset) {
        const initval = {
          CardID: "",
          CardType: "",
          Descryption: "",
          StartDate: "",
          EndDate: "",
          Score: "",
        };
        return this.dataset.flatMap((x) => Object.assign({}, initval, x));
      }
      return [];
    },
  },
  methods: {
    selectedItem(item, type) {
      if (!this.selected || !item || type != 'row') return;
      if (item.ID === this.selected.ID) return 'table-selected';
    },
    onRowClick(item) {
      this.$emit("select", item);
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  padding: 0;
}
</style>

<style lang="scss">
.schedule-list {
  thead tr {
    color: var(--black);
  }
  thead th {
    color: var(--black);
    font-family: var(--cpax-font-header);
    font-weight: bold;
  }
}

.schedule-list.table-striped tbody {
  tr:nth-of-type(odd) {
    font-family: var(--cpax-font-table);
    font-weight: 300;
    background-color: var(--primary);
    filter: brightness(130%);
    color: var(--black);
  }

  tr {
    &:hover {
      filter: brightness(80%);
    }
    &:focus {
      filter: brightness(90%);
      box-shadow: var(--cpax-box-shadow);
    }
    &.table-selected {
      filter: brightness(90%);
      box-shadow: var(--cpax-box-shadow);
    }
  }

  tr:nth-of-type(even) {
    font-family: var(--cpax-font-table);
    font-weight: 300;
    background-color: var(--white);
  }
}
</style>
