<template>
  <c-simple-dialog :id="id"
                   dialog-class="cpax-clone-schedule"
                   :title="T('Clone Selected Schedule')"
                   :ok-title="T('Clone')"
                   @ok="onClone">
    <b-container class="cpax-clone-schedule-form">
      <b-row class="form-row">
        <b-col>
          <span class="label">{{T('New Start Date')}}</span>
        </b-col>
        <b-col>
          <c-datepicker id="schedule-start-date"
                        v-model="v$.start.$model"
                        :state="vuelidateState(v$.start)" />
        </b-col>
      </b-row>
    </b-container>
  </c-simple-dialog>
</Template>

<script>

import useVuelidate from "@vuelidate/core";
import {required, minValue } from "@vuelidate/validators"
import { vuelidateState } from "@/lib/vuelidate-helper";

import { startOfDay, diff } from "@/lib/doses";
import moment from 'moment-timezone';

import cSimpleDialog from "./c-simple-dialog"
import cDatepicker from "./c-datepicker"

export default {
  components: {
    cSimpleDialog,
    cDatepicker,
  },
  setup() {
    return {
      v$:  useVuelidate(),
      vuelidateState,
    };
  },
  props: {
    id: {
      type: String,
      default: "cpax-clone-schedule",
    },
    schedule: {
      type: Object,
      required: true,
    },
    timezone: {
      type: String,
      default: () => moment.tz.guess(),
      required: true,
    }
  },
  data() {
    return {
      start: new Date(),
    };
  },
  validations() {
    return {
      start: {
        required,
        minValueOfSchedule: minValue(
          startOfDay(this.schedule.StartDate, this.timezone)
        )
      },
    }
  },
  methods: {
    onClone() {

      const duration = diff(this.schedule.Start, this.schedule.End);
      const end = moment(this.start).add(duration);

      var cloneSchedule = Object.assign({}, this.schedule);
      cloneSchedule.StartDate = this.start;
      cloneSchedule.EndDate = end;

      this.$emit('clone', cloneSchedule);
    }
  }
}
</script>
