<template>
  <c-simple-dialog
    dialog-class="cpax-contact-address-book-dialog"
    :id="id"
    :ok-title="okButton"
    :title="title"
    @ok="onOk"
    @cancel="onCancel"
  >
    <b-container v-show="show('list')">
      <b-row v-for="contact in contactList" :key="contact.Ref">
        <b-col class="cards">
          <c-info-card
            :contact="contact"
            @edit="onEdit(contact)"
            @remove="onRemove(contact)"
            @toggle="onToggle(contact)"
          />
        </b-col>
      </b-row>
    </b-container>

    <c-contact-address-book-add-edit
      v-show="show('add-edit')"
      :addMode="addMode"
      :contact="selectedContact"
      :title="subtitle"
    />

    <template #button-bar>
      <b-button
        v-if="selectedSection == 'add-edit' && !addMode"
        variant="danger"
        size="sm"
        @click="onRemove(selectedContact)"
      >
        {{ T('Delete Contact') }}
      </b-button>
    </template>
  </c-simple-dialog>
</template>

<script>
import CInfoCard from './c-info-card';
import CSimpleDialog from './c-simple-dialog';
import CContactAddressBookAddEdit from './c-contact-address-book-add-edit.vue';

const contacts = [
  {
    Status: false,
    Ref: "John Smith",
    Relation: "Family",
    Email: "j@smitty.com",
    Phone: "+15196671111",
    Profiles: [],
  },
  {
    Status: true,
    Ref: "Jane Smith",
    Relation: "Family",
    Email: "j@smitty.com",
    Phone: "+15196671111",
    Profiles: [
      "Monitor Profile",
    ],
  },
]

function newContact() {
  return {
    Ref: '',
    Relation: '',
    Phone: '',
    Email: '',
    Profiles: [],
  }
}

export default {
  components: {
    CInfoCard,
    CSimpleDialog,
    CContactAddressBookAddEdit,
},
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    contactList: {
      type: Array,
      default: () => { return contacts },
    }
  },
  data() {
    return {
      addMode: true,
      okButton: this.T('Add'),
      selectedContact: newContact(),
      selectedSection: 'list',
      subtitle: '',
    }
  },
  methods: {
    onAdd: function() {
      this.addMode = true;
      this.okButton = this.T('Save');
      this.selectedContact = newContact();
      this.selectedSection = 'add-edit';
      this.subtitle = 'Add New Contact';
    },
    onCancel: function(evt) {
      if (this.selectedSection == 'add-edit') {
        evt.preventDefault();
        this.reset();
      }
    },
    onEdit: function(contact) {
      this.addMode = false;
      this.okButton = this.T('Save');
      this.selectedContact = contact;
      this.selectedSection = 'add-edit';
      this.subtitle = 'Edit Contact'
    },
    onOk: function(evt) {
      evt.preventDefault();

      if (this.selectedSection == 'list') this.onAdd()
      else this.onSave(this.selectedContact)
    },
    onRemove: function(contact) {
      this.confirmationDialog(this.T("Are you sure you want to delete this contact?"), '', {
        okTitle: this.T("Yes"),
        cancelTitle: this.T("No"),
      })
      .then((res) => {
        if (res) {
          console.log(contact);
          // ADD API CALL
          this.reset();
        }
      })
      .catch((err) => {
        console.log("Remove pharmacist dialog error: " + err);
      });
    },
    onSave: function(contact) {
      // ADD API CALL
      console.log(contact);
      this.reset();
    },
    onToggle: function(contact) {
      // ADD API CALL
      contact.Status = !contact.Status;
    },
    reset() {
      this.okButton = this.T('Add');
      this.selectedContact = newContact();
      this.selectedSection = 'list';
    },
    show(section) {
      return this.selectedSection === section;
    }
  }
}
</script>

<style lang="scss" scoped>
.cards {
  margin-top: 1em;
}
</style>

<style lang="scss">
.cpax-contact-address-book-dialog {
  .modal-footer {
    display: flex;
    justify-content: space-between;
  }
}
</style>
