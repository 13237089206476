<template>
 <b-container class="cpax-repeated-dose-entry-panel">
    <b-row class="form-row">
      <b-col>
        <span class="label">{{ T('Starting Date') }}</span>
      </b-col>
      <b-col>
        <c-datepicker id="start-date"
                      v-model="v$.startDate.$model"
                      :state="vuelidateState(v$.startDate)" />
      </b-col>
   </b-row>
    <b-row class="form-row">
      <b-col>
        <span class="label">{{ T('Ending Date')}}</span>
      </b-col>
        <b-col>
          <c-datepicker id="end-date"
                        v-model="v$.endDate.$model"
                        :state=" vuelidateState(v$.endDate)" />
        </b-col>
    </b-row>
    <b-row class="form-row">
      <b-col>
        <span class="label">{{ T('Time')}}</span>
      </b-col>
      <b-col>
        <c-timepicker id="intake-time"
                      v-model="v$.time.$model"
                      :state="vuelidateState(v$.time)" />
      </b-col>
    </b-row>
    <b-row class="form-row">
      <b-col>
        <span class="label">{{T('Intake Range')}}</span>
      </b-col>
      <b-col>
        <b-input-group :append="T('Minutes')">
          <b-form-input id="intake"
                        type="number"
                        number
                        :state="vuelidateState(v$.intake)"
                        v-model="v$.intake.$model" />
        </b-input-group>
      </b-col>
    </b-row>
    <b-row class="form-row">
      <b-col>
        <span class="label">{{T('Description')}}</span>
      </b-col>
      <b-col>
        <b-form-textarea id="description"
                         v-model="v$.description.$model"
                         :state="vuelidateState(v$.description)"
                         :placeholder="T('Description')"
                         rows="4" >
        </b-form-textarea>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="button-bar">
        <b-button @click="onCancel" >{{ T("Cancel") }}</b-button>
        <b-button variant="primary" @click="onSave" >{{ T("Save") }}</b-button>
      </b-col>
   </b-row>
  </b-container>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { vuelidateState } from "@/lib/vuelidate-helper"

import cDatepicker from "./c-datepicker"
import cTimepicker from "./c-timepicker"

import { startOfDay, endOfDay, toTimePickerTime } from "@/lib/doses";
import { required, minValue, maxValue} from "@vuelidate/validators";

import moment from 'moment-timezone';

import { generateRepeatedDoses } from "@/lib/doses";

export default {
  components: {
      cDatepicker,
      cTimepicker,
  },
  setup() {
    return {
      v$: useVuelidate(),
      vuelidateState,
    }
  },
  props: {
    schedule: {
      type: Object,
      required: true,
    },
    timezone: {
      type: String,
      default: () => moment.tz.guess(),
      required: true,
    }
  },
  data() {
      const currentTime = toTimePickerTime(new Date(), this.timezone);

    return {
      startDate: moment(this.schedule.StartDate).toDate(),
      endDate: moment(this.schedule.EndDate).toDate(),
      time: currentTime,
      intake: 60,
      description: "",
    }
  },
  validations() {
    return {
      startDate: {
        required,
        minValueOfSchedule: minValue(
          startOfDay(this.schedule.StartDate, this.timezone).toDate())
      },
      endDate: {
        required,
        minValueOfStartDate: minValue(this.startDate),
        maxValueOfSchedule: maxValue(
          endOfDay(this.schedule.EndDate, this.timezone).toDate())
      },
      time: {
        required,
      },
      intake: {
        required,
      },
      description: {
        required,
      }
    }
  },
  methods: {
    onSave() {
      this.v$.$validate().then((v) => {
        if (v) {
          const doses = generateRepeatedDoses(this.startDate,
                                              this.endDate,
                                              this.time,
                                              this.timezone,
                                              this.intake,
                                              this.description);
          this.$emit('save', doses);
        }
      });
    },
    onCancel() {
      this.$emit('cancel');
    }
  }
}
</script>

<style lang="scss" scoped>
 .form-row {
   margin-bottom: 20px;
 }
 .button-bar {
   display: flex;
   justify-content: space-between;
 }
</style>

<style lang="scss" >
 .cpax-single-dose-entry-panel {
   label.form-control {
     &.is-invalid {
       background-image: unset;
     }
     &.is-valid {
       background-image: unset;
     }
   }
 }
 </style>
