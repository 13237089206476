<template>
  <b-container>
    <b-row v-for="(el, i) in v$.sections.$model" :key="el.Label" align-h="center" class="mt-4">
      <b-col>
        <b-row align-h="center">
          <b-col cols="10">
              <span class="">{{ v$.sections.$model[i].Label }}</span>
          </b-col>
        </b-row>
        <b-row align-h="center">
          <b-col cols="8">
            <b-form-textarea v-model="el.Content" rows="4" placeholder="Notification Content"></b-form-textarea>
          </b-col>
          <b-col cols="2">
              <b-form-checkbox v-model="v$.sections.$model[i].Active" size="lg"></b-form-checkbox>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col class="button-bar">
        <b-button @click="cancel">{{ T("Back") }}</b-button>
         <b-button variant="primary" @click="save">{{ T("Save") }}</b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import useVuelidate from "@vuelidate/core"
import { vuelidateState } from "@/lib/vuelidate-helper"
import { helpers, required, sameAs } from "@vuelidate/validators"
import { mapGetters } from 'vuex'

const defaultCommentSections = ["Adherence Content", "Non-adherence Content", "Poor Adherence Content"]

export default {
  setup() {
    return {
      v$: useVuelidate(),
      vuelidateState,
    }
  },
  data() {
    return {
      sections: [],
    }
  },
  validations() {
    return {
      sections: {
        $each: helpers.forEach({
          Label: {
            required,
          },
          Active: {
            sameAsBool: sameAs(false | true),
            required,
          },
          Content: {

          },
        })
      }
    }
  },
  computed: {
      ...mapGetters('systemsettings', ['getSetting'])
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    save() {
      this.confirmationDialog(this.T("Are you sure you want to save these changes?"), '', {
          okTitle: this.T("Yes"),
          cancelTitle: this.T("No"),
        })
        .then((res) => {
          if (res) {
            this.$emit('save', this.sections)
          }
        })
        .catch((err) => {
          console.log("Confirmation dialog error: " + err)
        })
    }
  },
  mounted: async function() {
    const vm = this
    try {
      await this.$store.dispatch('systemsettings/fetchSectionContent')

    } catch(e) {
      console.log(e)
    }

    let s = []
    defaultCommentSections.forEach(idx => {
        let v = vm.getSetting(idx) || { key: idx, value: { Label: idx, Content: "", Active: undefined } }
        s.push({ Label: v.value.Label, Content: v.value.Content, Active: v.value.Active })
    })
    vm.sections = s
  }
}
</script>

<style lang="scss" scoped>
  .button-bar {
    display: flex;
    justify-content: space-between;
  }
</style>
