<template>
  <div>
  <vue-cal
    class="calendar-view"
    hide-view-selector
    default-view="week"
    :time-from="0 * 60"
    :time-to="24 * 60"
    :min-cell-width="45"
    :time-cell-height="65"
    time-format="hh:mm am"
    date-format="dddd D MMMM YYYY"
    :locale="Language()"
    no-event-overlaps
    :events="events"
    :disable-views="['years', 'year', 'month', 'day']"
    :on-event-click="onEventClick"
  >
    <template v-slot:event="{ event }">
      <div :id="event.dose.ID">
        <div class="vuecal__event-title" v-html="T(event.title)"></div>
        <small class="vuecal__event-time">
          <div>{{event.dose.Title}}</div>
          <div>{{ event.start.formatTime("hh:mm am") }} : {{ event.end.formatTime("hh:mm am") }}</div>
        </small>
      </div>
      <b-tooltip v-if="event.dose.BlisterIndex != 0" :target="event.dose.ID" triggers="hover">
        <div>{{event.blister}}</div>
        <div>{{event.dose.Title}}</div>
        <div v-show="event.taken">{{T('taken')}} : {{event.taken}}</div>
      </b-tooltip>
    </template>
  </vue-cal>
  <c-dose-event-editor-dialog id="cpax-dose-event-editor-dialog"
                              :schedule="schedule"
                              :timezone="timezone"
                              :dose="selectedDose" />
  </div>
</template>

<script>
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";
import "vue-cal/dist/i18n/es.js";
import cDoseEventEditorDialog from "./c-dose-event-editor-dialog";

export default {
  components: {
    VueCal,
    cDoseEventEditorDialog,
  },
  props: {
    schedule: {
      type: Object,
      required: true,
    },
    timezone: {
      type: String,
      required: true,
    },
    doses: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectedDose: {},
    };
  },
  computed: {
    events() {
      return this.doses;
    }
  },
  methods: {
    onEventClick(event) {
      console.log(event)
      this.selectedDose = event.dose;
      this.$bvModal.show("cpax-dose-event-editor-dialog");
    }
  },
  filters: {
    device: function(value) {
      if (!value) return "";
      let parts = value.split(".");
      if (!parts[2]) return value.substring(value.length - 6, value.length);
      return parts[2];
    },
    round: function(value) {
      if (!isNaN(parseFloat(value)) && isFinite(value))
        return Math.round(value * 100) / 100.0;
      return "";
    },
    powerlevel: function(value) {
      return Math.abs(value);
    }
  },
};
</script>

<style lang="scss">
@import "@/themes/branding.scss";

.lock-icon {
  margin: 2px 5px;
}

.vuecal__time-column {
  width: 4em;
}
.vuecal__menu,
.vuecal__cell-events-count {
  background-color: #48525a;
}
.vuecal__title-bar {
  background-color: #e5e5e5;
}

.vuecal__event {
  cursor: pointer;
}

.vuecal__event.Close {
  background-color: var(--dose-close);
  color: #fff;
  justify-content: center;
  align-items: center;
}

.vuecal__event.Overdue {
  background-color: var(--dose-overdue);
  color: #fff;
  justify-content: center;
  align-items: center;
}

.vuecal__event.Ok {
  background-color: var(--dose-good);
  color: #fff;
  justify-content: center;
  align-items: center;
}

.vuecal__event.Late {
  background-color: var(--dose-late);
  color: #fff;
  justify-content: center;
  align-items: center;
}

.vuecal__event.Close .vuecal__event-time {
  align-items: center;
  font-size: 0.75em;
}
.vuecal__event.Open .vuecal__event-time {
  align-items: center;
  font-size: 0.75em;
}

.mx-input-append {
  padding: 0px;
}

.mx-datepicker {
  display: block;
  line-height: 1.5;
}

.mx-input {
  padding: 0px;
  border: none;
  box-shadow: none;
  height: 24px;
}
</style>
