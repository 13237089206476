<template>
  <c-brand-page>
    <b-container>
      <b-row>
        <b-col>
          <c-signin-card />
        </b-col>
      </b-row>
    </b-container>
  </c-brand-page>
</template>

<script>
import cBrandPage from "@/components/c-brand-page";
import cSigninCard from "@/components/c-signin-card";

export default {
  name: 'viewLogin',
  components: {
    cBrandPage,
    cSigninCard,
  },
}
</script>

<style lang="scss">
@import '@/themes/branding.scss';
</style>
