<template>
  <b-container class="dose-status" :class="statusClass">
    <b-col cols="auto" align-self="center">
      <font-awesome-icon :icon="['fas', 'trash']" />
    </b-col>
    <b-col>
      <b-container>
        <b-row>
          <b-col>Patient Reference</b-col>
        </b-row>
        <b-row>
          <b-col class="info">{{ patientRef }}</b-col>
        </b-row>
      </b-container>
    </b-col>
    <b-col>
      <b-container>
        <b-row>
          <b-col>Schedule Type</b-col>
        </b-row>
        <b-row>
          <b-col class="info">{{ scheduleType }}</b-col>
        </b-row>
      </b-container>
    </b-col>
    <b-col>
      <b-container>
        <b-row>
          <b-col>Anomaly</b-col>
        </b-row>
        <b-row>
          <b-col class="info">{{ T('anomaly-' + anomaly) }}</b-col>
        </b-row>
      </b-container>
    </b-col>
    <b-col cols="auto" class="search">
      <b-button>
        <font-awesome-icon :icon="['fas', 'search']" />
      </b-button>
    </b-col>
  </b-container>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTrash, faSearch } from '@fortawesome/free-solid-svg-icons';
library.add(faTrash, faSearch);

export default {
  props: {
    patientRef: {
      type: String,
    },
    scheduleType: {
      type: String,
    },
    anomaly: {
      type: String,
    },
    adherenceStatus: {
      type: String,
    },
  },
  computed: {
    statusClass() {
      return this.adherenceStatus;
    },
  },
}
</script>

<style lang="scss" scoped>

.dose-status {
  display: flex;
  border-radius: 10px;
  padding: 0;
  margin: 1em;
  font-size: 0.7em;
  box-shadow: var(--cpax-box-shadow);
  .row {
    margin: 10px;
  }
  .info {
    font-weight: bold;
    font-size: 1.2em;
    margin-top: -5px;
  }
  .search {
    text-align: right;
    padding: 0;
    margin: 0;
    .btn {
      padding: 20px;
      min-width: 60px;
      color: white;
      border: none;
      border-radius: 10px;
    }
  }
  &.missed {
    background-color: var(--anomaly-missed);
    color: var(--anomaly-missed-accent);
    .btn, .btn:hover  {
      background-color: var(--anomaly-missed-accent);
    }
  }

  &.overdose {
    background-color: var(--anomaly-overdose);
    color: var(--anomaly-overdose-accent);
    .btn, .btn:hover  {
      background-color: var(--anomaly-overdose-accent);
    }

  }

  &.ontime {
    background-color: var(--anomaly-ontime);
    color: var(--anomaly-ontime-accent);
    .btn, .btn:hover  {
      background-color: var(--anomaly-ontime-accent);
    }

  }

  &.nostatus {
    background-color: var(--anomaly-nostatus);
    color: var(--anomaly-nostatus-accent);
    .btn, .btn:hover  {
      background-color: var(--anomaly-nostatus-accent);
    }

  }
 }
</style>
