<template>
    <b-container>
        <b-row v-for="(el, i) in v$.sections.$model" :key="el.Label" align-h="center" class="mt-4">
            <b-col cols="6">
                <span class="">{{ v$.sections.$model[i].Label}}</span>
            </b-col>
            <b-col cols="2">
                <b-form-checkbox v-model="v$.sections.$model[i].Active" size="lg"></b-form-checkbox>
            </b-col>
        </b-row>
        <b-row class="mt-5">
            <b-col class="button-bar">
                <b-button @click="cancel">{{ T("Back") }}</b-button>
                <b-button variant="primary" @click="save">{{ T("Save") }}</b-button>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { vuelidateState } from "@/lib/vuelidate-helper";
import { helpers, required, sameAs } from "@vuelidate/validators";
import { mapGetters } from 'vuex';

const defaultReportSections = ["Intake Behaviour Trends", "Adherence Performance Trends", "Comments"];

export default {
    setup() {
        return {
            v$: useVuelidate(),
            vuelidateState,
        };
    },
    data() {
        return {
            sections: []
        };
    },
    validations() {
        return {
            sections: {
                $each: helpers.forEach({
                    Label: {
                        required,
                    },
                    Active: {
                        sameAsBool: sameAs(false | true),
                        required,
                    },
                }),
            },
        };
    },
    computed: {
      ...mapGetters('systemsettings', ['getSetting'])
    },
    methods: {
        cancel() {
            this.$emit("cancel");
        },
        save() {
            const vm = this
            this.confirmationDialog(
                this.T("Are you sure you want to save these changes?"),
                "",
                {
                    okTitle: this.T("Yes"),
                    cancelTitle: this.T("No"),
                })
                .then((res) => {
                    if (res) {
                        vm.$emit("save", vm.sections);
                    }
                })
                .catch((err) => {
                    console.log("Confirmation dialog error: " + err);
                });
        },
    },
    mounted: function() {
        const vm = this;
        this.$store.dispatch('systemsettings/fetchReportSections').then(() => {
            let s = [];
            defaultReportSections.forEach(idx => {
              const v = vm.getSetting(idx) || {key: idx, value: {Label: idx, Active: false}}
              console.log("report section", idx, v)
              s.push({ Label: v.value.Label, Active: v.value.Active });
            });
            this.sections = s;
        });
    }
};
</script>

<style lang="scss" scoped>
.button-bar {
    display: flex;
    justify-content: space-between;
}
</style>
