<template>
  <b-container>
    <b-row v-show="!show('buttons')" class="subtitle">
      <b-col>{{ title }}</b-col>
    </b-row>
    <c-manage-report-content-buttons v-show="show('buttons')"
                                     @incl-excl-sections="inclExclSections"
                                     @manage-comments-content="manageCommentsContent" />
    <c-include-exclude-report-sections v-show="show('incl-excl')"
                                       @save="onSaveReportSection"
                                       @cancel="onCancel" />
    <c-section-content-modify-enable v-show="show('manage-comments')"
                                       @save="onSaveSectionContent"
                                       @cancel="onCancel" />
  </b-container>
</template>

<script>
import cIncludeExcludeReportSections from "./c-include-exclude-report-sections.vue";
import cManageReportContentButtons from "./c-manage-report-content-buttons.vue";
import cSectionContentModifyEnable from "./c-section-content-modify-enable.vue";

export default {
  components: {
    cManageReportContentButtons,
    cIncludeExcludeReportSections,
    cSectionContentModifyEnable,
  },
  data() {
    return {
      selectedSection: 'buttons',
      title: '',
    }
  },
  methods: {
    inclExclSections() {
      this.selectedSection = 'incl-excl'
      this.title = 'Include/Exclude Report Sections'
    },
    manageCommentsContent() {
      this.selectedSection = 'manage-comments'
      this.title = 'Manage Comments Section Content'
    },
    show(section) {
      return this.selectedSection === section;
    },
    onCancel() {
      this.reset();
    },
    onSaveReportSection(data) {
      this.$store.dispatch('systemsettings/updateReportSections', data).then(() => {
        this.reset();
      }).catch((e) => {
        console.log(e)
        this.notificationDialog(this.T("Error") + ': ' + this.T('Saving Report Section'))
      })
    },
    onSaveSectionContent(data) {
      this.$store.dispatch('systemsettings/updateSectionContent', data).then(() => {
        this.reset();
      }).catch((e) => {
        console.log(e)
        this.notificationDialog(this.T("Error") + ': ' + this.T('Saving Report Section'))
      })
    },
    reset() {
      this.selectedSection = 'buttons';
    }
  },
}
</script>

<style lang="scss" scoped>
  .button-center {
    padding-top: 50px;
    text-align: center;
  }
  button.btn-outline-primary,
  button.btn-outline-primary:not(:disabled) {
    color: var(--primary);
    padding: 10px;
  }
  .subtitle {
    font-size: 25px;
    font-weight: 400;
    margin: -30px 0 10px 0;
  }
</style>
