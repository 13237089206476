<template>
  <b-container fluid class="cpax-notification-edit">
    <b-row v-for="notification in dataset" :key="notification._key">
      <b-col>
        <c-notification-edit-form :notification="notification"
             :readonly="readonly"
             @update="onUpdateNotification"
             @delete="onDelete(notification)" />
      </b-col>
    </b-row>
    <b-row v-show="!readonly">
      <b-col class="button-center">
        <b-button variant="outline-primary"
                  @click="addNewNotification" >
          + {{ T('Add New Notification') }}
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="button-bar">
        <b-button @click="cancel" >{{ T("Cancel") }}</b-button>
        <b-button variant="primary" @click="save" >{{ T("Save") }}</b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import cNotificationEditForm from "./c-notification-edit-form"

export default {
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  components: {
    cNotificationEditForm,
  },
  props: {
    notifications: {
      type: Array,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    let i = 0
    return {
      dataset: this.notifications.map(n => Object.assign({_key: i++}, n)),
    };
  },
  methods: {
    addNewNotification() {
      this.$emit('add-new-notification');
    },
    cancel() {
      this.$emit('cancel');
    },
    save() {
      this.v$.$validate().then((v) => {
        if (v) {
          this.$emit('save', [...this.dataset]);
        }
      }).catch((e) => {
        //TODO: need to notify the error
        console.log(e);
      });
    },
    onDelete(notification) {
      const idx = this.dataset.indexOf(notification);
      this.dataset.splice(idx, 1);
    },
    onUpdateNotification(evt) {
      const idx = this.dataset.indexOf(evt.oldValue);
      Object.assign(this.dataset[idx], evt.newValue);
    }
  },
  watch: {
    notifications(newValue) {
      let i = 0
      this.dataset = newValue.map(n => Object.assign({_key: i++}, n));
    }
  }
}
</script>

<style lang="scss" scoped>
 .button-bar {
   display: flex;
   justify-content: space-between;
 }
</style>

<style lang="scss" >
 .cpax-notification-edit {
   .button-center {
     padding: 20px 10px;
     text-align: center;
   }
   button.btn-outline-primary,
   button.btn-outline-primary:not(:disabled) {
     color: var(--primary);
     padding: 10px;
   }
 }
</style>
