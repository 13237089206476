<template>
  <c-side-panel-dialog :id="id" :title="currentMenuTitle">
    <template #sidepanel>
      <c-selectable-menu class="menu"
                         :items="controlPanelMenuItems"
                         :title="T('Control Panel')"
                         @change="onSelectedMenuItemsChanged" />
    </template>


    <div class="scrolling-panel">
      <c-control-panel-notification-profile v-show="showPanel('notification-panel')" />
      <c-control-panel-timedate v-show="showPanel('timedate-panel')"
                                @cancel="onClose"
                                @save="onClose" />
      <c-control-panel-manage-pharmacists v-show="showPanel('manage-pharmacists-panel')" />
      <c-control-panel-manage-report-content v-show="showPanel('manage-report-content-panel')" />
      <c-control-panel-manage-sms v-show="showPanel('manage-sms-panel')" @cancel="onClose" />
    </div>
  </c-side-panel-dialog>
</template>

<script>
import cSidePanelDialog from "./c-side-panel-dialog";
import cSelectableMenu from "./c-selectable-menu";
import cControlPanelNotificationProfile from "./c-control-panel-notification-profile";
import cControlPanelTimedate from "./c-control-panel-timedate";
import cControlPanelManagePharmacists from "./c-control-panel-manage-pharmacists";
import cControlPanelManageReportContent from "./c-control-panel-manage-report-content";
import cControlPanelManageSms from "./c-control-panel-manage-sms";

export default {
  components: {
    cSidePanelDialog,
    cSelectableMenu,
    cControlPanelManagePharmacists,
    cControlPanelTimedate,
    cControlPanelNotificationProfile,
    cControlPanelManageReportContent,
    cControlPanelManageSms,
},
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      default: "Control Panel",
    },
  },
  data() {
    const menuItems = [
      { label: "Set/Edit Notification", panel: "notification-panel" },
      { label: "Set Time & Date Defaults", panel: "timedate-panel" },
      { label: "Manage Pharmacists", panel: "manage-pharmacists-panel" },
      { label: "Manage Report Content", panel: "manage-report-content-panel" },
      { label: "Manage non-reminder SMS", panel: "manage-sms-panel" },
    ];

    return {
      controlPanelMenuItems: menuItems,
      currentSelectedMenuItem: menuItems[0],
      admins: [
        { id: 1, name: "Steve Beaulac", email: "steve@beaulac.me" },
        { id: 2, name: "Neda Beaulac", email: "neda@beaulac.me" },
        { id: 3, name: "Tim Beaulac", email: "tim@beaulac.me" },
        { id: 4, name: "Pasha Beaulac", email: "pasha@beaulac.me" },
        { id: 8, name: "Keyan Beaulac", email: "keyan@beaulac.me" },
        { id: 9, name: "John Beaulac", email: "john@beaulac.me" },
        { id: 10, name: "Kir Beaulac", email: "kir@beaulac.me" },
      ],
      helpMessage: {},
      smsMessage: {},
      pharmacy: {},
      reportSection: {},
      pharmacist: {},
    };
  },
  computed: {
    currentMenuTitle: function() {
      return (this.currentSelectedMenuItem) ? this.T(this.currentSelectedMenuItem.label) : "";
    }
  },
  methods: {
    showPanel(panel) {
      return this.currentSelectedMenuItem.panel === panel;
    },
    onClose() {
      this.$bvModal.hide(this.id);
    },
    onSave: function () {
      this.$emit("save");

    },
    onSelectedMenuItemsChanged: function (evt) {
      console.log(evt);
      this.currentSelectedMenuItem = evt.new;
    },
    onEditPharmacist: function (pharmacist) {
      this.pharmacist = pharmacist;
      this.$bvModal.show("add-edit-pharmacist")
    },
    onSavePharmacist: function (pharmacist) {
        const idx = this.admins.findIndex(x => x.id == pharmacist.id)
        this.admins[idx] = pharmacist
    },
    onDelete: function (pharmacist) {
      this.admins = this.admins.filter((x) => pharmacist.id != x.id);
    },
  },
};
</script>

<style lang="scss" scope>
 .scroling-panel {
   max-height: 100px;
   overflow: auto;
 }
</style>
