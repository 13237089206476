<template>
  <b-container class="cpax-manage-pharmacists-buttons">
    <b-row>
      <b-col class="button-center">
        <b-button variant="outline-primary"
                  @click="add">
          {{ T('Add New Pharmacists') }}
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mb-5">
      <b-col class="button-center">
        <b-button variant="outline-primary"
                  @click="edit">
          {{ T('Edit Existing Pharmacists') }}
        </b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  methods: {
    add() {
      this.$emit('add')
    },
    edit() {
      this.$emit('edit')
    }
  }
}
</script>

<style lang="scss" scoped>
  .button-center {
    padding-top: 50px;
    text-align: center;
  }
  button.btn-outline-primary,
  button.btn-outline-primary:not(:disabled) {
    color: var(--primary);
    padding: 10px;
  }
</style>
