<template>
  <b-container fluid class="footer">
    <b-row>
      <b-col cols="auto" align-self="center">
        <c-language-switcher />
      </b-col>
      <b-col class="copyright" align-self="end">&copy; {{ year }} Copyright Jones Healthcare Group. All rights reserved.</b-col>
      <b-col cols="auto" class="about">
        <div>{{ domain_name }}</div>
        <div>{{ T("Jones Healthcare Group") }}</div>
        <div>{{ T("Medication Adherence Portal") }}</div>
        <div>{{ T('Version')}} UI: {{ui_version}} API: {{api_version}}</div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import cLanguageSwitcher from "@/components/c-language-switcher.vue";
import { ui_version, api_version } from "@/app.config";

export default {
  components: { cLanguageSwitcher },
  computed: {
    domain_name() {
      return location.hostname;
    },
    ui_version() {
      return ui_version;
    },
    api_version() {
      return api_version;
    },
    year() {
      var dt = new Date()
      return dt.getFullYear()
    }
  },
};
</script>

<style lang="scss" scoped>
.footer {
  font-family: var(--font-family-normal);
  font-size: 12px;
  z-index: 999;
  color: white;
  background-color: var(--banner-background);
  padding: 10px;
  .copyright {
    text-align: center;
  }
  .about > div {
    text-align: right;
    padding: 0;
  }
}

.version {
  color: gray;
  font-size: 1rem;
}
</style>
