<template>
<b-container fluid>
  <b-row>
    <c-patients-report-bar :patient-count="patientCount" :intervention-count="interventionCount"/>
  </b-row>
  <b-row>
    <b-col>
      <b-card no-body class="cpax-tabs" >
        <b-tabs card content-class="mt-3" nav-class="cpax-patient-section-tabs">
          <b-tab>
            <template #title>
              <font-awesome-icon :icon="['fas', 'list-ul']" />
              {{ T("Participants") }}
            </template>
            <c-patients-grid @change="onPatientChange" :patients="patients" />
          </b-tab>
          <b-tab>
            <template #title>
              <font-awesome-icon :icon="['fas', 'list-ul']" />
              {{ T("Watchllist") }}
            </template>
            <c-patients-grid @change="onPatientChange" :patients="watchlist" />
          </b-tab>
        </b-tabs>
      </b-card>
    </b-col>
  </b-row>
  <b-row>
    <b-col class="spacer">
    </b-col>
  </b-row>
</b-container>
</template>

<script>
import { mapGetters } from "vuex";
import cPatientsGrid from "@/components/c-patients-grid";
import cPatientsReportBar from "@/components/c-patients-report-bar";

import { library } from '@fortawesome/fontawesome-svg-core';
import { faListUl } from '@fortawesome/free-solid-svg-icons';
library.add(faListUl);

export default {
  components: {
    cPatientsGrid,
    cPatientsReportBar,
  },
  data: function() {
    return {
      filter: "",
      sortFunction: [
        {
          label: "Patient Name",
          cmp: (a, b) => {
            return String(a.Name).localeCompare(b.Name);
          }
        },
        {
          label: "Start Date",
          cmp: (a, b) => {
            return Date.parse(a.StartDate) - Date.parse(b.StartDate);
          }
        },
        {
          label: "Descending Start Date",
          cmp: (a, b) => {
            return Date.parse(b.StartDate) - Date.parse(a.StartDate);
          }
        }
      ],
      comparer: null
    };
  },
  computed: {
    patientCount() {
      return this.patients.length;
    },
    interventionCount() {
      return this.$store.state.patients.anomalyList.length;
    },
    patients() {
      let cmp =
        this.comparer == null ? this.sortFunction[0].cmp : this.comparer;
      return this.filteredByReference(this.filter).sort(cmp);
    },
    watchlist() {
        return this.patients.filter(p => p.Watch === true);
    },
    ...mapGetters("patients", { filteredByReference: "filteredByReference" })
  },
  methods: {
    onFilterTextChanged($event) {
      this.filter = $event;
    },
    onSortFunctionChanged($event) {
      this.comparer = this.sortFunction[$event.idx].cmp;
    },
    onPatientChange($event) {
      const _vm = this;
      this.$store.dispatch("patients/storePatient", $event).catch((err) => {
        console.log(err);
        _vm.notificationDialog(_vm.T("Error"), _vm.T("can't remove selected schedule."));
      });
    }
  },
  created() {
    this.$store.dispatch("patients/getAllPatients").catch((err) => {
      console.log(err);
    });

    this.$store.dispatch("patients/updateAnomalyList").catch((err) => {
      console.log(err);
    });
  }
};
</script>


<style lang="scss">
 .cpax-tabs {
   .cpax-patient-section-tabs {
     .nav-link {
       color: var(--black);
     }
   }

   .card-header {
     border-radius: 23px 23px 0 0;
   }

   &.card {
     border-radius: 23px;
   }

   .tab-pane {
     border: 0px solid var(--secondary);
     border-radius: 23px;
     background-color: white;
     padding: 0;
   }

   .tab-content {
     background-color: white;
     border: 0 solid white;
   }
}
.spacer {
  height: 4.8rem;
 }
</style>
