<template>
  <b-container>
    <b-row align-h="center" class="mt-5">
      <b-col class="text-right" cols="3">
        Default Timezone
      </b-col>
      <b-col cols="4">
        <b-form-select v-model="timezone" :options="timezoneList"></b-form-select>
      </b-col>
    </b-row>
    <b-row align-h="center" class="mt-5">
      <b-col class="text-right" cols="3">
        Date Format
      </b-col>
      <b-col cols="4">
        <b-form-select v-model="format" :options="formatList"></b-form-select>
      </b-col>
    </b-row>
    <b-row align-h="center" class="mt-5">
      <b-col class="text-right" cols="3">
        24H Format
      </b-col>
      <b-col cols="4">
        <b-form-checkbox v-model="clock24h" size="lg"></b-form-checkbox>
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col class="button-bar">
        <b-button @click="onCancel">{{ T("Cancel") }}</b-button>
        <b-button variant="primary" @click="onSave">{{ T("Save") }}</b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
// TODO:
// How many options for timezones/formats do we want?
// How do we want to store the values?
const timezoneList = [
  { value: "EST", text: "EST (UTC-4:00)" },
  { value: "PST", text: "PST (UTC-7:00)" },
];
const formatList = [
  { value: 0, text: "YYYY-MM-DD" },
  { value: 1, text: "YY-MM-DD" },
];

export default {
  data() {
    return {
      formatList,
      format: 0,
      timezoneList,
      timezone: "EST",
      clock24h: false,
    }
  },
  methods: {
    onCancel() {
      this.$emit("cancel")
    },
    onSave() {
      const settings = {
        format: this.format,
        timezone: this.timezone,
        clock24h: this.clock24h,
      }
      console.log(settings)
      this.$emit("save")
    }
  }
}
</script>

<style lang="scss" scoped>
  .button-bar {
    display: flex;
    justify-content: space-between;
  }
</style>
