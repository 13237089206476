<template>
  <c-default-card class="signin" :title="T('Sign in')" :btnText="T('Login')" @submit="login">
    <b-alert class="danger" :show="showFailureMessage">
      {{ T("Authentication Failed") }}. {{T("Please try again")}}.
    </b-alert>
    <b-form-group :label="T('Email')" label-for="login-username">
      <b-input-group>
        <b-form-input
          type="email"
          id="login-username"
          v-model="v$.email.$model"
          @keyup.enter="$refs.password.focus()"
          :state="vuelidateState(v$.email)"
          :invalid-feedback="T(v$.email.$error[0])"
          :placeholder="T('Enter email')"
        />
      </b-input-group>
    </b-form-group>
    <b-form-group :label="T('Password')" label-for="login-password">
      <b-input-group>
        <b-form-input
          ref="password"
          type="password"
          id="login-password"
          v-model="password"
          @keyup.enter="login"
          :placeholder="T('Password')"
        />
      </b-input-group>
    </b-form-group>
    <b-container fluid>
      <b-row>
        <b-col align-content="right" class="forgot-password-link">
          <router-link
            to="/reset-password/request"
            class="forgot-password-link"
          >{{ T("Forgot Password") }}?</router-link>
        </b-col>
      </b-row>
    </b-container>
  </c-default-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import useVuelidate from "@vuelidate/core"
import { required, email } from "@vuelidate/validators";

import { vuelidateState } from "@/lib/vuelidate-helper"

import cDefaultCard from "@/components/c-default-card"

export default {
    setup: () => ({
      v$: useVuelidate(),
      vuelidateState
    }),
    components: {
      cDefaultCard,
    },
  data: function() {
    return {
      email: "",
      password: "",
      showFailureMessage: false
    };
  },
  validations() {
    const localRules = {
      email: { required, email }
    }
    return localRules
  },
  computed: {
    ...mapGetters(["isAuthenticated"])
  },
  methods: {
    ...mapActions(["authenticate"]),
    async login(evt) {
      evt.preventDefault();
      const  isValidInput = await this.v$.$validate()
      if (isValidInput)  {
        const _vm = this
        await this.authenticate ({
          username: _vm.email,
          password: _vm.password
        });
        if (_vm.isAuthenticated) {
          _vm.$router.push("/patients/list");
        } else {
          _vm.showFailureMessage = true;
        }
      }
    }
  }
};
</script>


<style lang="scss" scoped>
 .default-card.signin {
   min-width: 24rem;
   max-width: 50%;
 }

 .danger {
   background-color: var(--danger);
   color: var(--white);
   font-weight: bold;
   filter: alpha(opacity=0.5);
   border-color: var(--danger);
   box-shadow: 0 0 0.2rem var(--danger);
 }

.forgot-password-link {
  font-family: var(--font-brand-normal);
  font-size: 1.2rem;
  text-align: right;
}
</style>
