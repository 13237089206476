<template>
  <b-container class="cpax-contact-address-book-add-edit">
    <b-row class="subtitle">
      <b-col>{{ title }}</b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group 
          label-for="contact-reference"
          :label="T('Contact Reference')"
          :state="vuelidateState(v$.contact.Ref)"
        >
          <b-form-input id="contact-reference" v-model="v$.contact.Ref.$model" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label-for="contact-relation"
          :label="T('Relationship to Patient')"
          :state="vuelidateState(v$.contact.Relation)"
        >
          <b-form-input id="contact-relation" v-model="v$.contact.Relation.$model" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label-for="contact-phone"
          :label="T('Phone Number')"
          :state="vuelidateState(v$.contact.Phone)"
        >
          <b-form-input id="contact-phone" v-model="v$.contact.Phone.$model" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label-for="contact-email"
          :label="T('Email Address')"
          :state="vuelidateState(v$.contact.Email)"
        >
          <b-form-input id="contact-email" v-model="v$.contact.Email.$model" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="!addMode">
      <b-col>
        <b-form-group
          label-for="contact-profiles"
          :label="T('Enrolled Notification Profiles')"
          :state="vuelidateState(v$.contact.Profiles)"
        >
          <b-form-tags
            id="contact-profiles"
            v-model="v$.contact.Profiles.$model"
            class="p-0 border-0"
            no-outer-focus
            remove-on-delete
          >
            <template v-slot="{ tags, inputAttrs, inputHandlers, tagVariant, addTag, removeTag }">
              <b-input-group class="mb-2">
                <b-form-select
                  :options="Object.keys(profiles)"
                  v-bind="inputAttrs"
                  v-on="inputHandlers"
                  class="form-control"
                ></b-form-select>
                <b-input-group-append>
                  <b-button @click="addTag()" variant="outline-primary">Add</b-button>
                </b-input-group-append>
              </b-input-group>
              <div>
                <b-form-tag
                  v-for="tag in tags"
                  @remove="removeTag(tag)"
                  :key="tag"
                  :title="tag"
                  no-remove
                  class="mr-2 mb-2"
                  :variant="tagVariant"
                >
                  <span class="tag-content">
                    {{ tag }}
                    <button type="button" class="close" @click="removeTag(tag)">
                      <font-awesome-icon :icon="['far', 'circle-xmark']" />
                    </button>
                  </span>
                </b-form-tag>
              </div>
            </template>
          </b-form-tags>
        </b-form-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { email, required } from "@vuelidate/validators";
import { phonenumber } from "@/validators";
import { vuelidateState } from "@/lib/vuelidate-helper";
import { mapState } from "vuex";

export default {
  setup() {
    return {
      vuelidateState,
      v$: useVuelidate(),
    }
  },
  props: {
    addMode: {
      default: true,
    },
    contact: {
      required: true,
    },
    title: {
      default: '',
    },
  },
  validations: {
    contact: {
      Ref: {
        required,
      },
      Relation: {
        required,
      },
      Phone: {
        required,
        phonenumber,
      },
      Email: {
        required,
        email,
      },
      Profiles: {
        required,
      },
    },
  },
  computed: {
    ...mapState('notification', ["profiles"]),
  },
  methods: {
    remove() {
      this.confirmationDialog(this.T("Are you sure you want to delete this contact?"), '', {
        okTitle: this.T("Yes"),
        cancelTitle: this.T("No"),
      })
      .then((res) => {
        if (res) {
          this.$emit('remove', this.contact);
        }
      })
      .catch((err) => {
        console.log("Remove contact error: " + err);
      });
    },
    save() {
      this.$emit('save', this.contact)
    }
  },
  mounted: function() {
    this.$store.dispatch('notification/get');
  },
}
</script>

<style lang="scss" scoped>
.button-bar {
  display: flex;
  justify-content: space-between;
}
button.btn-outline-primary,
button.btn-outline-primary:not(:disabled) {
  color: var(--primary);
  padding: 5px 10px;
}
.close {
  font-size: 1em;
  line-height: 1.25em;
  padding: 2px;
}
.subtitle {
  font-size: 25px;
  font-weight: 400;
  margin-top: -30px;
  margin-bottom: 10px;
}
.tag-content, .close {
  color: var(--light);
}
.tag-content {
  font-size: 1.25em;
  line-height: 1.5em;
  padding: 2px 5px;
}
</style>
