<template>
<b-container fluid class="page">
  <c-nav-bar class="header" :page="this.$route.meta.title" />
  <router-view class="content" />
  <c-footer class="footer"/>
</b-container>
</template>

<script>
import cNavBar from "@/components/c-nav-bar";
import cFooter from "@/components/c-footer";

export default {
  components: { cNavBar, cFooter },
}
</script>

<style lang="scss" scoped>
.page {
    margin: 0px;
    padding: 0px;
}

.content {
    margin-top: 120px;
}

.header {
    z-index: 999;
    position: fixed;
    top: 0;
}

.footer {
    z-index: 999;
    position: fixed;
    width: 100%;
    bottom: 0;
}
</style>
