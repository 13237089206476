var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"cpax-manage-pharmacists-add-edit"},[_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label-for":"pharmacist-name","label":_vm.T('Pharmacist Name'),"state":_vm.vuelidateState(_vm.v$.pharmacist.Name)}},[_c('b-form-input',{attrs:{"id":"pharmacist-name"},model:{value:(_vm.v$.pharmacist.Name.$model),callback:function ($$v) {_vm.$set(_vm.v$.pharmacist.Name, "$model", $$v)},expression:"v$.pharmacist.Name.$model"}})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label-for":"pharmacist-empid","label":_vm.T('Employee ID'),"state":_vm.vuelidateState(_vm.v$.pharmacist.Empid)}},[_c('b-form-input',{attrs:{"id":"pharmacist-empid"},model:{value:(_vm.v$.pharmacist.Empid.$model),callback:function ($$v) {_vm.$set(_vm.v$.pharmacist.Empid, "$model", $$v)},expression:"v$.pharmacist.Empid.$model"}})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label-for":"pharmacist-email","label":_vm.T('Pharmacist Email Address'),"state":_vm.vuelidateState(_vm.v$.pharmacist.Email)}},[_c('b-form-input',{attrs:{"id":"pharmacist-email"},model:{value:(_vm.v$.pharmacist.Email.$model),callback:function ($$v) {_vm.$set(_vm.v$.pharmacist.Email, "$model", $$v)},expression:"v$.pharmacist.Email.$model"}})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label-for":"pharmacist-groups","label":_vm.T('Associate to your pharmacy'),"state":_vm.vuelidateState(_vm.v$.pharmacist.Groups)}},[_c('b-form-tags',{staticClass:"p-0 border-0",attrs:{"id":"pharmacist-groups","separator":" ,;","no-outer-focus":"","remove-on-delete":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var tags = ref.tags;
var inputAttrs = ref.inputAttrs;
var inputHandlers = ref.inputHandlers;
var tagVariant = ref.tagVariant;
var addTag = ref.addTag;
var removeTag = ref.removeTag;
return [_c('b-input-group',{staticClass:"mb-2"},[_c('b-form-input',_vm._g(_vm._b({staticClass:"form-control"},'b-form-input',inputAttrs,false),inputHandlers)),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":function($event){return addTag()}}},[_vm._v("Add")])],1)],1),_c('div',_vm._l((tags),function(tag){return _c('b-form-tag',{key:tag,staticClass:"mr-2 mb-2",attrs:{"title":tag,"no-remove":"","variant":tagVariant},on:{"remove":function($event){return removeTag(tag)}}},[_c('span',{staticClass:"tag-content"},[_vm._v(" "+_vm._s(tag)+" "),_c('button',{staticClass:"close",attrs:{"type":"button"},on:{"click":function($event){return removeTag(tag)}}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'circle-xmark']}})],1)])])}),1)]}}]),model:{value:(_vm.v$.pharmacist.Groups.$model),callback:function ($$v) {_vm.$set(_vm.v$.pharmacist.Groups, "$model", $$v)},expression:"v$.pharmacist.Groups.$model"}})],1)],1)],1),_c('b-row',{staticClass:"mt-3"},[_c('b-col',{staticClass:"button-bar"},[(!_vm.addMode)?_c('b-button',{attrs:{"variant":"danger"},on:{"click":_vm.remove}},[_vm._v(_vm._s(_vm.T("Delete")))]):_vm._e(),_c('b-button',{on:{"click":_vm.cancel}},[_vm._v(_vm._s(_vm.T("Cancel")))]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.T("Save")))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }