<template>
  <b-container class="cpax-manage-pharmacists-add-edit">
    <b-row>
      <b-col>
        <b-form-group label-for="pharmacist-name"
                      :label="T('Pharmacist Name')"
                      :state="vuelidateState(v$.pharmacist.Name)">
          <b-form-input id="pharmacist-name" v-model="v$.pharmacist.Name.$model" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label-for="pharmacist-empid"
                      :label="T('Employee ID')"
                      :state="vuelidateState(v$.pharmacist.Empid)">
          <b-form-input id="pharmacist-empid" v-model="v$.pharmacist.Empid.$model" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label-for="pharmacist-email"
                      :label="T('Pharmacist Email Address')"
                      :state="vuelidateState(v$.pharmacist.Email)">
          <b-form-input id="pharmacist-email" v-model="v$.pharmacist.Email.$model" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label-for="pharmacist-groups"
                      :label="T('Associate to your pharmacy')"
                      :state="vuelidateState(v$.pharmacist.Groups)">
          <b-form-tags id="pharmacist-groups"
                       separator=" ,;"
                       class="p-0 border-0"
                       no-outer-focus
                       remove-on-delete
                       v-model="v$.pharmacist.Groups.$model">
            <template v-slot="{ tags, inputAttrs, inputHandlers, tagVariant, addTag, removeTag }">
              <b-input-group class="mb-2">
                <b-form-input v-bind="inputAttrs"
                              v-on="inputHandlers"
                              class="form-control">
                </b-form-input>
                <b-input-group-append>
                  <b-button @click="addTag()" variant="outline-primary">Add</b-button>
                </b-input-group-append>
              </b-input-group>
              <div>
                <b-form-tag v-for="tag in tags"
                            @remove="removeTag(tag)"
                            :key="tag"
                            :title="tag"
                            no-remove
                            class="mr-2 mb-2"
                            :variant="tagVariant">
                  <span class="tag-content">
                    {{ tag }}
                    <button type="button" class="close" @click="removeTag(tag)">
                      <font-awesome-icon :icon="['far', 'circle-xmark']" />
                    </button>
                  </span>
                </b-form-tag>
              </div>
            </template>
          </b-form-tags>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col class="button-bar">
        <b-button v-if="!addMode" variant="danger" @click="remove">{{ T("Delete") }}</b-button>
        <b-button @click="cancel">{{ T("Cancel") }}</b-button>
        <b-button variant="primary" @click="save">{{ T("Save") }}</b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { email, required } from "@vuelidate/validators";
import { vuelidateState } from "@/lib/vuelidate-helper";

export default {
  setup() {
    return {
      vuelidateState,
      v$: useVuelidate(),
    }
  },
  props: {
    addMode: {
      default: true,
    },
    pharmacist: {
      required: true,
    },
  },
  data() {
    return {
    }
  },
  validations: {
    pharmacist: {
      Name: {
        required,
      },
      Empid: {
        required,
      },
      Email: {
        email,
        required,
      },
      Groups: {
        required,
      },
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    remove() {
      this.confirmationDialog(this.T("Are you sure you want to delete this pharmacist?"), '', {
          okTitle: this.T("Yes"),
          cancelTitle: this.T("No"),
        })
        .then((res) => {
          if (res) {
            this.$emit('remove', this.pharmacist);
          }
        })
        .catch((err) => {
          console.log("Remove pharmacist dialog error: " + err);
        });
    },
    save() {
      this.$emit('save', this.pharmacist);
    },
  }
}
</script>

<style lang="scss" scoped>
  .button-bar {
    display: flex;
    justify-content: space-between;
  }
  button.btn-outline-primary,
  button.btn-outline-primary:not(:disabled) {
    color: var(--primary);
    padding: 5px 10px;
  }
  .close {
    font-size: 1em;
    line-height: 1.25em;
    padding: 2px;
  }
  .tag-content, .close {
    color: var(--light);
  }
  .tag-content {
    font-size: 1.25em;
    line-height: 1.5em;
    padding: 2px 5px;
  }
</style>
