<template>
  <b-container class="cpax-notification-edit-form">
    <b-row>
      <b-col>
        <b-form-input id="notification-name"
                      :readonly="readonly"
                      v-model="v$.name.$model"
                      @blur="update"
                      :placeholder="T('Notification Name')"
                      :invalid-feedback="T(v$.name.$error[0])"
                      :state="vuelidateState(v$.name)"/>
      </b-col>
      <b-col>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-textarea id="notification-content"
                    v-model="v$.content.$model"
                    rows="6"
                    @blur="update"
                    :invalid-feedback="T(v$.content.$error[0])"
                    :state="vuelidateState(v$.content)"
                    :placeholder="T('Notification Content')" />
      </b-col>
      <b-col>
        <b-container>
          <b-row>
            <b-col class="delay" cols="auto" align-self="center" >
              <b-form-input id="notification-delay"
                            type="number"
                            min="0"
                            number
                            @blur="update"
                            v-model="v$.delay.$model" />
            </b-col>
            <b-col>
              <b-form-group id="notification-delay-group"
                            label-for="notification-delay"
                            :label="T('minutes from window closing')"
                            :state="vuelidateState(v$.delay)"
                            :invalid-feedback="T(v$.delay.$error[0])" />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-checkbox class="caretaker"
                               @blue="update"
                               v-model="v$.caretaker.$model">
                {{ T("Notify Caretaker") }}
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-row v-show="!readonly">
            <b-col>
              <b-link class="trash" @click="onDelete">
                <font-awesome-icon :icon="['fas', 'trash-can']" />
                Delete notification
              </b-link>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { vuelidateState } from "@/lib/vuelidate-helper";
import { required } from "@vuelidate/validators";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
library.add(faTrashCan);

export default {
  setup() {
    return {
      v$: useVuelidate(),
      vuelidateState
    }
  },
  props: {
    notification: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  validations() {
    return {
      name: {
        required
      },
      content: {
        required
      },
      delay: {
        required
      },
      caretaker: {

      }
    };
  },
  data() {
    return {
      name: "",
      content: "",
      caretaker: false,
      delay: 0,
    }
  },
  methods: {
    onDelete(evt) {
      this.$emit('delete', evt)
    },
    update() {
      const o = {Name: this.name, Content: this.content, Caretaker: this.caretaker, Delay: this.delay};
      this.$emit('update', {newValue: o, oldValue: this.notification})
    }
  },
  mounted() {
    const o = Object.assign({Name: '', Content: '', Caretaker: false, Delay: 0}, this.notification);
    this.name = o.Name;
    this.content = o.Content;
    this.delay = o.Delay;
    this.caretaker = o.Caretaker;
  },
}
</script>

<style lang="scss" scoped>
 .cpax-notification-edit-form {
   font-size: 20px;
   font-family: var(--font-brand-normal);
   .delay {
     padding-left: 0;
     input {
       width: 4rem;
     }
   }
 }
</style>

<style lang="scss">
 .cpax-notification-edit-form {
  .col {
     padding:5px;
   }
   a.trash {
     color: var(--black);
   }
   .custom-control-input:checked ~ .custom-control-label::before {
     margin: auto;
     border-color: black;
     background-color: black;
   }
   .custom-checkbox .custom-control-label::before {
     border-radius: 0.5rem;
     top: 0.5rem;
   }
   .custom-control-input:checked ~ .custom-control-label::after {
     color: var(--black);
     top: 0.5rem;
   }
 }

</style>
