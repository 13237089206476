<template>
  <b-container class="blister" :class="{state}" >
    {{ label }}
  </b-container>
</template>

<script lang="js">
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    state: {
      type: Boolean,
      required: true,
    }
  },
}
</script>

<style lang="scss" scoped>

.blister {
  &:hover {
    cursor: pointer;
  }
  display: flex;
  border-radius: 14px;
  text-align: center;
  align-items: center;
  margin: auto;
  justify-content: center;
  min-height: 4em;
  min-width: 4em;
  border: 5px solid var(--secondary);
}

 .state {
   border: 5px solid var(--primary);
 }
</style>
