<template>
  <b-container class="cpax-patient-settings-form">
    <b-row>
      <b-col>
        <b-form-group label-for="patient-reference"
                      :label="T('Patient Reference')"
                      :state="vuelidateState(v$.patient.Name)">
          <b-form-input id="patient-name"
                        @update="onUpdate"
                        v-model="v$.patient.Name.$model" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label-for="patient-phonenumber"
                      :label="T('Phone Number')" >
          <vue-phone-number-input id="patient-phonenumber"
                                  @update="onUpdatePhone"
                                  :state="vuelidateState(v$.patient.Phone)"
                                  v-model="phone"
                                  :translations="phoneNumberInputTranslations()" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label-for="patient-start-date"
                      :label="T('Start Date')" >
          <b-form-datepicker id="patient-start-date"
                             v-model="v$.patient.StartDate.$model"
                             :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                             value-as-date
                             @input="onUpdate"
                             :state="vuelidateState(v$.patient.StartDate)"
                             local="en">
          </b-form-datepicker>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label-for="patient-email"
                      :label="T('Email')" >
          <b-form-input id="patient-email"
                        @update="onUpdate"
                        :state="vuelidateState(v$.patient.Email)"
                        v-model="v$.patient.Email.$model" />
         </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label-for="patient-language"
                      :label="T('Preferred Language')" >
          <b-form-select id="patient-language"
                         :options="availablebLanguage"
                         :state="vuelidateState(v$.patient.Language)"
                         @change="onUpdate"
                         v-model="v$.patient.Language.$model" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label-for="patient-timezone"
                      :label="T('Patient\'s Timezone')" >
          <b-form-select id="patient-timezone"
                         v-model="v$.patient.Timezone.$model"
                         :state="vuelidateState(v$.patient.Timezone)"
                         @change="onUpdate"
                         :options="availableTimezone" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label-for="patient-notification-message"
                      :label="T('Notification Profile')" >
          <b-form-select v-model="notificationProfile"
                         @change="onNotificationProfileChanged"
                         :options="profileOptions" />
        </b-form-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState } from 'vuex';
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { vuelidateState } from "@/lib/vuelidate-helper";
import { phonenumber } from "@/validators";

import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

import { getAvailableTimezone } from '@/lib/timezone';

import { languageList } from '@/lib/translator';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
library.add(faUserPlus);

import { Patient } from '@/models'

export default {
  setup: function() {
    return {
      vuelidateState,
      v$: useVuelidate(),
    };
  },
  components: {
    VuePhoneNumberInput,
  },
  props: {
    value: {
      type: Patient,
      required: true,
    },
  },
  data() {
    return {
      phone: "",
      notificationProfile: "",
      patient: {},
    }
  },
  computed: {
    ...mapState('notification', ['profiles']),
    profileOptions() {
      return Object.keys(this.profiles)
    },
    availablebLanguage() {
      return languageList
    },
    availableTimezone() {
      return getAvailableTimezone()
    },
  },
  validations() {
    return {
      patient: {
        Name: { required },
        Language: { required },
        StartDate: { required },
        Timezone: { },
        Phone: {
          required,
          phonenumber,
        },
        Email: { email },
        NotificationProfile: { required },
      }
    };
  },
  methods: {
    onUpdatePhone(phone) {
      this.v$.patient.Phone.$model = phone.formattedNumber;
      this.onUpdate();
    },
    onNotificationProfileChanged(val) {
      const p = this.profiles[val];
      this.patient.NotificationProfile = p;
      this.onUpdate();
    },
    onUpdate() {
      const patient = Object.assign({}, this.patient);
      console.log(patient)
      this.$emit('input', patient)
    }
  },
  mounted() {
    this.patient = Object.assign(new Patient(), this.value);
    this.$store.dispatch('notification/get').then(() => {
      if (!this.patient.NotificationProfile) {
        this.notificationProfile = this.profileOptions[0];
        this.patient.NotificationProfile = this.profiles[this.notificationProfile];
      } else {
        this.notificationProfile = this.patient.NotificationProfile.Name;
      }
    });
    this.phone = this.patient.Phone;
  }
}
</script>
