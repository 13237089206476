<template>
  <b-container class="cpax-manage-pharmacists-list">
    <b-row>
      <b-col>
        <b-input-group>
          <b-form-input v-model="searchFilter" placeholder="Search" />
          <b-input-group-append>
            <b-input-group-text>
              <font-awesome-icon :icon="['fas', 'search']" />
            </b-input-group-text>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
    <b-row v-for="pharmacist in items" :key="pharmacist.ID">
      <b-col class="cards">
        <c-pharmacist-card :value="pharmacist" @edit="onEdit(pharmacist)" @delete="onDelete(pharmacist)" />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import cPharmacistCard from "@/components/c-pharmacist-card";

export default {
  components: {
    cPharmacistCard,
  },
  props: {
    dataset: {
      type: Array,
      default: () => { return [] },
    }
  },
  data() {
    return {
      searchFilter: '',
    }
  },
  computed: {
    copyset() {
      if (this.dataset) {
        return this.dataset.flatMap((x) => Object.assign({}, x));
      }
      return []
    },
    items() {
      if (this.searchFilter != '') {
        return this.copyset.filter(x => {
          const v = (x['Name'].toLowerCase() || '') + (x['Email'].toLowerCase() || '')
          return v.search(this.searchFilter.toLowerCase()) > -1
        })
      }
      return this.copyset
    }
  },
  methods: {
    onEdit: function(admin) {
      this.$emit('edit', admin)
    },
    onDelete: function(admin) {
      this.$emit('delete', admin)
    }
  }
}
</script>

<style lang="scss" scoped>
  .cards {
    margin-top: 1rem;
  }

  .cpax-manage-pharmacists-list {
    margin-bottom: 1rem;
  }

  input.form-control {
    background-color: #e9ecef;
    padding: 1.5rem;
    border: 0px solid transparent;
    border-radius: 10px;
  }

  input.form-control:focus {
    box-shadow: none;
    --webkit-box-shadow: none;
  }

  .input-group-append {
    border: 0px solid transparent;
    .input-group-text {
      border: 0px solid transparent;
      border-radius: 0px 10px 10px 0px;
    }
  }
</style>
