<template>
  <b-container>
    <b-row>
      <b-col class="button-center">
        <b-button variant="outline-primary"
                  @click="inclExclSections">
          {{ T('Include/Exclude Report Sections') }}
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mb-5">
      <b-col class="button-center">
        <b-button variant="outline-primary"
                  @click="manageCommentsContent">
          {{ T('Manage Comments Section Content') }}
        </b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  methods: {
    inclExclSections() {
      this.$emit('incl-excl-sections')
    },
    manageCommentsContent() {
      this.$emit('manage-comments-content')
    }
  }
}
</script>

<style lang="scss" scoped>
  .button-center {
    padding-top: 50px;
    text-align: center;
  }
  button.btn-outline-primary,
  button.btn-outline-primary:not(:disabled) {
    color: var(--primary);
    padding: 10px;
  }
</style>
