<template>
  <b-container class="blister" :class="adherenceStatus" @click="onClick">{{ index }}</b-container>
</template>

<script lang="js">
export default {
  props: {
    index: {
      type: Number,
      required: true,
    },
    item: {
      type: Object,
    },
  },
  computed: {
    adherenceStatus() {
      if (this.item) {
          return this.item.class;
      }
      return "blank"
    }
  },
  methods: {
    onClick() {
      this.$emit("click", this.item);
    }
  }
}
</script>

<style lang="scss" scoped>

.blister {
  display: flex;
  border-radius: 20px;
  text-align: center;
  align-items: center;
  margin: auto;
  justify-content: center;
  min-height: 4em;
  min-width: 4em;
  background-color: var(--dose-blank);
}

 .Late {
   background-color: var(--dose-late);
   color: var(--dose-late-accent);
 }

 .Overdose {
   background-color: var(--dose-overdose);
   color: var(--dose-overdose-accent);
 }

 .Upcoming {
   background-color: var(--dose-upcoming);
   color: var(--dose-upcoming-accent);
 }

 .Overdue {
   background-color: var(--dose-overdue);
   color: var(--dose-overdue-accent);
 }

 .Ok {
   background-color: var(--dose-good);
   color: var(--dose-good-accent);
 }
</style>
