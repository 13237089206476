<template>
  <div class="grid-container">
    <b-alert show class="information" >
      <small>Shift+Mouse click: toggle column</small>
      <small>Ctrl+Mouse click: toggle row</small>
    </b-alert>
    <div class="total-doses">
      {{T('Total Doses')}}
      <b-badge variant="secondary">{{total}}</b-badge>
    </div>
    <div class="grid">
    <table>
      <tr v-for="row in rows" :key="row">
        <td v-for="col in cols" :key="col">
          <div class="spacing"
              @click.exact="onSelect(row, col)"
              @click.ctrl ="onRowSelect(row)"
              @click.shift="onColumnSelect(col)"
          >
            <c-wizard-grid-blister
              :label="(blister(row, col) + 1).toString()"
              :state="state(row, col)" />
          </div>
        </td>
      </tr>
    </table>
    </div>
  </div>
</template>

<script>
import cWizardGridBlister from './c-wizard-grid-blister';

export default {
  components: {
    cWizardGridBlister,
  },
  props: {
    rows: {
      type: Number,
      required: true,
    },
    cols: {
      type: Number,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    index: {
      type: String,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      card: Array.from({length: this.rows * this.cols}, () => true),
    }
  },
  computed: {
    total() {
      if (this.card) {
        return (this.cols * this.rows) - this.card.filter(x => x === false).length;
      }
      return 0
    },
  },
  methods: {
    state: function(row, col) {
      return this.card[this.blister(row, col)];
    },
    blister: function(row, col) {
      return (((row - 1) * this.cols) + col) - 1;
    },
    updateModel: function(cache) {
      this.$children.forEach((c) => c.$forceUpdate());
      let s = Object.assign({}, this.value);
      s[this.index] = cache;
      this.$emit('input', s);
    },
    onSelect: function(row, col) {
      if (this.readonly)
        return

      const idx = this.blister(row, col);
      this.$set(this.card, idx, !this.card[idx]);
      this.updateModel(this.card);

    },
    onColumnSelect: function(col) {
      if (this.readonly)
        return;

      const c = col - 1;
      const s = !this.card[c];
      for (let i = 0; i < this.rows; i++) {
        this.card[(i * this.cols) + c] = s;
      }
      this.$set(this.card, 0, this.card[0])
    },
    onRowSelect: function(row) {
      if (this.readonly)
        return;

      const r = row - 1;
      const s = !this.card[r * this.cols];
      for (let i = 0; i < this.cols; i++) {
        this.card[r * this.cols + i] = s;
      }
      this.$set(this.card, 0, this.card[0])
    }
  },
  mounted() {
    if (Array.isArray(this.value[this.index])) {
      this.card = this.value[this.index];
    }
    this.updateModel(this.card);
  },
  watch: {
    card: function(v) {
      if (this.readonly)
        return

      this.updateModel(v);
    }
  }
}
</script>


<style lang="scss" scoped>
 .information {
   display: flex;
   justify-content: space-between;
   padding: 10px 20px;
   margin: 20px 20%;
 }
 .grid-container {
   margin: 20px;
   text-align: center;
   justify-content: center;
 }
 .grid {
   width: 100%;
   display: flex;
   justify-content: center;
 }
 .spacing {
   padding: 10px;
 }
</style>
