<template>
  <b-container class="patient-report">
    <b-row align-h="center">
      <b-col></b-col>
      <b-col cols="2" class="section">
        {{ T('Patient') }}
        <b-badge variant="dark">{{patientCount}}</b-badge>
      </b-col>
      <b-col cols="2" class="section">
        {{ T('Intervention Report Alerts') }}
        <b-badge variant="danger">{{interventionCount}}</b-badge>
      </b-col>
      <b-col></b-col>
    </b-row>
  </b-container>
</template>

<script>
 export default {
   props: {
     patientCount: { type: Number, default: 0 },
     interventionCount: { type: Number, default: 0 },
   }
 }
</script>


<style lang="scss" scope>
 .patient-report {
   font-family: var(--font-brand-normal);
   .section {
     padding: 1rem;
     white-space: nowrap;
     .badge {
       padding: .5rem;
       margin: .5rem;
     }
   }
 }
</style>
