<template>
  <b-modal :id="id"
           dialog-class="cpax-wizard-dialog"
           :content-class="contentClass"
           size="xl"
           :footer-class="footerClass"
           no-close-on-backdrop
           @ok="ok">
    <template #modal-header="{ close }">
      <h5 class="modal-title">{{T(title)}}</h5>
      <button type="button" class="close" @click="close()">
        <font-awesome-icon :icon="['far', 'circle-xmark']" />
      </button>
    </template>
    <slot v-bind:validator="v$" />
    <template #modal-footer="{ ok, cancel }">
      <b-button variant="secondary" @click="cancel">{{T('Cancel')}}</b-button>
      <div class="cpax-btn-group">
        <b-button class="back-btn" v-if="!noBackBtn" variant="secondary" @click="back">{{T('Back')}}</b-button>
        <b-button class="skip-btn" v-if="SkipBtn" variant="secondary" @click="skip">{{skipTitle}}</b-button>
        <b-button variant="primary" @click="ok">{{ nextTitle }}</b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import useVuelidate from "@vuelidate/core";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
library.add(faCircleXmark);

export default {
  setup: () => ({
    v$: useVuelidate(),
  }),
  props: {
    title: {
      type: String,
      required: true,
    },
    nextTitle: {
      type: String,
      default: "Next",
    },
    id: {
      type: String,
      required: true,
    },
    footerClass: {
      type: String,
      default: 'cpax-simple-dialog-footer',
    },
    contentClass: {
      type: String,
      default: 'cpax-simple-dialog-content',
    },
    noBackBtn: {
      type: Boolean,
      default: false,
    },
    SkipBtn: {
      type: Boolean,
      default: false,
    },
    skipTitle: {
      type: String,
      default: 'Skip',
    },
  },
  methods: {
    skip(bvModalEvt) {
      this.$bvModal.hide(this.id);
      this.$emit('next', bvModalEvt);
    },
    ok(bvModalEvt) {
      bvModalEvt.preventDefault();
      const vm = this;
      this.v$.$validate().then((v) => {
        if (v) {
          vm.$bvModal.hide(vm.id);
          vm.$emit("next", bvModalEvt);
        }
      });
    },
    back(bvModalEvt) {
      this.$bvModal.hide(this.id);
      this.$emit("back", bvModalEvt);
    }
  },
};
</script>

<style lang="scss" scoped>
.modal-title {
  font-family: var(--font-brand-normal);
  font-size: 24px;
  font-weight: 700;
}

.close {
  font-weight: 700;
  font-size: 24px;
  opacity: 0.75;
}

.close:not(:disabled):not(.disabled):hover {
  opacity: 1;
}
 .cpax-btn-group {
   align-content: space-between;
   .skip-btn,
   .back-btn {
     margin-right: 54px;
   }
 }

</style>

<style lang="scss" >
.cpax-wizard-dialog {
  .modal-header {
    border-bottom: none;
  }
  .modal-footer {
    justify-content: space-between;
    border-top: none;
  }
}
</style>
