<template>
  <table>
    <tr v-for="row in rows" :key="row">
      <td v-for="col in cols" :key="col">
        <c-blister
          :index="((row - 1) * cols) + col"
          :item="blister(((row - 1) * cols) + col)"
          @click="onClick"
        />
      </td>
    </tr>
  </table>
</template>

<script>
import cBlister from "@/components/c-blister";

export default {
  components: { cBlister },
  props: {
    dataset: {
      type: Array,
    },
    rows: {
      type: Number,
      required: true,
    },
    cols: {
      type: Number,
      required: true,
    },
  },
  methods: {
    blister(idx) {
      return this.dataset.find(item => item.dose.BlisterIndex == idx)
    },
    onClick(evt) {
      this.$emit("selectedItem", evt)
    },
   },
}
</script>

<style lang="scss" scoped>
table {
  border-collapse: collapse;
  height: 100%;
  cell-paddind: 1em;
  margin: 10px;
  td {
    padding: 15px;
  }
}
</style>
