<template>
    <b-container class="cpax-control-panel-manage-pharmacists">
        <b-row v-show="!show('buttons')" class="subtitle">
            <b-col>{{ title }}</b-col>
        </b-row>
        <c-manage-pharmacists-buttons-vue v-show="show('buttons')" @add="add" @edit="edit" />
        <c-add-edit-pharmacist-vue v-show="show('add-edit')" :addMode="addMode" :pharmacist="selectedPharmacist"
            @save="onSave" @remove="onDelete" @cancel="onCancel" />
        <c-existing-pharmacist-list-vue v-show="show('existing')" :dataset="userlist" @delete="onDelete"
            @edit="onEdit" />
    </b-container>
</template>

<script>
import { mapState } from "vuex";

import cManagePharmacistsButtonsVue from "./c-manage-pharmacists-buttons.vue";
import cAddEditPharmacistVue from "./c-manage-pharmacists-add-edit.vue";
import cExistingPharmacistListVue from "./c-manage-pharmacists-list.vue";

function newPharmacist() {
    return {
        Name: '',
        Empid: '',
        Email: '',
        Groups: [],
    }
}

export default {
    components: {
        cManagePharmacistsButtonsVue,
        cAddEditPharmacistVue,
        cExistingPharmacistListVue,
    },
    data() {
        return {
            addMode: true,
            selectedPharmacist: newPharmacist(),
            selectedSection: 'buttons',
            title: '',
        };
    },
    computed: {
        ...mapState({ users: (state) => state.users.list }),
        userlist() {
            return this.users
        }
    },
    methods: {
        add() {
            this.addMode = true;
            this.selectedPharmacist = newPharmacist();
            this.selectedSection = 'add-edit';
            this.title = 'Add New Pharmacist';
        },
        edit() {
            this.addMode = false;
            this.selectedSection = 'existing';
            this.title = 'Edit Existing Pharmacists'
        },
        onCancel() {
            if (this.addMode) {
                this.reset();
            } else {
                this.edit();
            }
        },
        onDelete(pharmacist) {
            this.$store.dispatch('users/delete', pharmacist).then(() => {
                this.edit();
                console.log(pharmacist);
            }).catch((err) => {
                console.log(err);
                this.notificationDialog(this.T('Error'), this.T('unable to remove user'))
            });
        },
        onEdit(pharmacist) {
            console.log('selected pharmacist', pharmacist)
            this.selectedPharmacist = pharmacist;
            this.selectedSection = 'add-edit';
        },
        onSave(pharmacist) {
            console.log(pharmacist)
            const action = (this.addMode) ? 'users/create' : 'users/save';

            this.$store.dispatch(action, pharmacist).then(() => {
                console.log(pharmacist)
                this.reset();
            }).catch((err) => {
                console.log(err)
                this.notificationDialog(this.T('Error'), this.T('unable to update user'))
            })
        },
        reset() {
            this.$emit('reset');
            this.selectedPharmacist = newPharmacist();
            this.selectedSection = 'buttons';
        },
        show(section) {
            return this.selectedSection === section;
        },
    },
    mounted: function () {
        console.log("mounted manage Pharmacists")
        this.$store.dispatch('users/getAll')
    }
}
</script>

<style lang="scss" scoped>
.button-center {
    padding-top: 50px;
    text-align: center;
}

button.btn-outline-primary,
button.btn-outline-primary:not(:disabled) {
    color: var(--primary);
    padding: 10px;
}

.subtitle {
    font-size: 25px;
    font-weight: 400;
    margin: -30px 0 10px 0;
}
</style>
