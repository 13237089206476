<template>
<c-simple-dialog :id="id" :title="T('Assign a Package')" :ok-title="T('Assign')" @ok="onAssign">
  <c-technology-eac-code-form v-if="technologies == 'eac'" v-model="dataset" index="EACCode" />
  <c-technology-nfc-code-form v-if="technologies == 'nfc'" v-model="dataset" index="NFCCode" />
</c-simple-dialog>
</template>

<script>
import { mapState } from "vuex";

import cSimpleDialog from "./c-simple-dialog"
import cTechnologyEacCodeForm from "./c-technology-eac-code-form"
import cTechnologyNfcCodeForm from "./c-technology-nfc-code-form"

export default {
  components: {
    cSimpleDialog,
    cTechnologyEacCodeForm,
    cTechnologyNfcCodeForm
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dataset: {}
    }
  },
  computed: {
    ...mapState({ schedule: (state) => state.schedules.selectedSchedule }),
    technologies() {
      return this.schedule["Technologies"];
    }
  },
  methods: {
    onAssign: function() {
      //assign card if data is provided
      const scheduleid = this.schedule.ID;

      console.log("Schedule", scheduleid)
      let action = null;
      const eacCode = this.dataset["EACCode"];
      if (eacCode && eacCode.length > 0) {
        console.log("EAC", eacCode);
        action = this.$store.dispatch("schedules/assignCardToEAC", {scheduleid, code: eacCode});
      }

      const nfcCode = this.dataset["NFCCode"];
      if (nfcCode && nfcCode.length > 0) {
        console.log("NFC", nfcCode);
        action = this.$store.dispatch("schedules/assgnCardToNFC", {scheduleid, code: nfcCode});
      }
      if (action == null) {
        this.notificationDialog(this.T('Error') + ': ' +  this.T('Assigning package to schedule'));
      } else {
        console.log(action);
        action.catch(err => {
          console.log(err);
          this.notificationDialog(this.T('Error') + ': ' +  this.T('Assigning package to schedule'));
        });
      }
    },
  },
  watch: {
    schedule: function(v) {
      this.dataset = Object.assign({}, v)
      console.log(this.dataset)
    }
  }
 }
</script>
