<template>
  <b-modal :id="id" hide-header hide-footer>
    <div class="default-dialog">
      <b-container fluid class="header">
        <b-row>
          <b-col>{{ title }}</b-col>
        </b-row>
      </b-container>
      <div v-if="$slots.default" class="body">
        <slot></slot>
      </div>
      <b-container class="btn-bar">
        <b-row class="text-center">
          <b-col align-content="center" class="button cancel">
            <b-button variant="secondary" @click="onCancel">{{ T("Cancel") }}</b-button>
          </b-col>
          <b-col align-content="center" class="button">
            <b-button variant="primary" type="submit" @click="onBtnClick">
              <span class='btn-label'>{{ okText }}</span></b-button>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
    },
    title: {
      type: String,
    },
    okText: {
      type: String,
      default: "Ok",
    },
  },
  methods: {
    onBtnClick: function(evt) {
      this.$emit("ok", evt);
    },
    onCancel: function() {
      this.$emit("cancel");
    },
  }
}
</script>

<style lang="scss" scoped>
.modal-content {
  .btn-label {
    white-space: nowrap;
  }
  margin: auto;
  max-width: 24rem;
  border-radius: 20px;
  .header {
    font-family: var(--font-brand-component-header);
    color: var(--black);
    font-weight: 700px;
    font-size: 24px;
    height: auto;
    mix-blend-mode: normal;
    text-align: center;
    padding-bottom: 1.5em;
  }
  .btn-bar {
    text-align: center;
    padding-top: 1em;
  }
  .body {
    font-family: var(--font-brand-normal);
  }

}
</style>
