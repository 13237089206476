<template>
  <b-modal size="xl" hide-footer hide-header :id="id" @shown="onShown"
           content-class="cpax-side-panel-dialog">
    <b-container >
      <b-row>
        <b-col v-if="$slots.sidepanel" class="side-panel" cols="3">
          <slot name="sidepanel" />
        </b-col>
        <b-col class="content" cols="9">
          <b-container fluid>
            <b-row align-h="between">
              <b-col cols="auto" class="title">
                <slot name="title">{{ title }}</slot>
              </b-col>
              <b-col cols="auto" align-self="center" class="close" >
                <button type="button" class='close' @click="onClose">
                  <font-awesome-icon :icon="['far', 'circle-xmark']" />
                </button>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="body">
                <slot />
              </b-col>
            </b-row>
          </b-container>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
library.add(faCircleXmark);

export default {
  props: {
    id: {
      type: String,
    },
    title: {
      type: String,
    },
  },
  methods: {
    onClose: function() {
      this.$bvModal.hide(this.id);
    },
    onShown: function(evt) {
      this.$emit('shown', evt);
    }
  }
}
</script>

<style lang="scss" scoped>
 .body {
   margin-top: 30px;
 }
</style>

<style lang="scss">
 .cpax-side-panel-dialog {
   border-radius: 25px;
   .title {
     font-family: var(--font-brand-normal);
     font-weight: 400;
     font-size: 42px;
   }
   .close {
     text-align: right;
     .btn {
       color: black;
       font-size: 24px;
       padding: 0px;
       border: none;
       box-shadow: none;
     }
   }

   .modal-body {
     padding: 0;
     border-radius: inherit;
   }
   .side-panel {
     border-top-left-radius: var(--cpax-dialog-border-radius);
     border-bottom-left-radius: var(--cpax-dialog-border-radius);
     background-color: var(--secondary);
   }
   .content {
     color: black;
     padding: 10px;
     border-top-right-radius: var(--cpax-dialog-border-radius);
     border-bottom-right-radius: var(--cpax-dialog-border-radius);
   }
 }
</style>
