<template>
  <b-container>
    <b-row>
      <b-col cols="auto">
        <c-doses-grid :cols="4" :rows="7" :dataset="dataset" @selectedItem="onSelectedDose" />
      </b-col>
      <b-col cols="auto">
        <c-dose-card :item="selectedItem" />
      </b-col>
      <b-col></b-col>
    </b-row>
  </b-container>
</template>

<script>
//import { mapState } from "vuex";
import cDosesGrid from "@/components/c-doses-grid";
import cDoseCard from "@/components/c-dose-card";

export default {
  components: {
    cDosesGrid,
    cDoseCard,
  },
  props: {
    doses: Array,
  },
  data() {
    return {
      selectedItem: {},
    };
  },
  computed: {
    dataset() {
      return this.doses;
    },
  },
  methods: {
    onSelectedDose(item) {
      if (item) {
        this.selectedItem = item;
      }
    },
  },
  watch: {
    doses: function() {
      this.selectedItem = {};
    }
  },
};
</script>

<style lang="scss" scoped>

.testing {
  align-items: center;
  .pharmacy-card,
  .patient-card,
  .brand-card,
  .admin-card,
  .contact-card {
    table {
      width: 100%;
      margin: 0 2em;
      border-collapse: separate;
    }

    td {
      padding-bottom: 2em;
      font-size: 1.7em;
    }

    th {
      font-size: 1.5em;
      text-align: left;
      padding-bottom: 0.5em;
    }

    td {
      font-weight: bold;
    }
  }
}
</style>
