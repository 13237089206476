<template>
  <c-side-panel-dialog :id="id" :title="currentMenuTitle" >
    <template #sidepanel>
      <c-selectable-menu class="menu"
                         :items="menuItems"
                         :title="T('Dose Scheduler')"
                         @change="onSelectedMenuItemsChanged" />
    </template>
    <div class="scrolling-panel">
      <c-repeated-dose-entry-panel v-show="showPanel('repeated-dose-panel')"
                                   :schedule="schedule"
                                   :timezone="timezone"
                                   @save="onSave"
                                   @cancel="onCancel"
      />

      <c-single-dose-entry-panel v-show="showPanel('single-dose-panel')"
                                 :schedule="schedule"
                                 :timezone="timezone"
                                 @save="onSave"
                                 @cancel="onCancel"
      />

      <c-weekly-dose-entry-panel v-show="showPanel('weekly-dose-panel')"
                                 :schedule="schedule"
                                 :timezone="timezone"
                                 @save="onSave"
                                 @cancel="onCancel"
      />
    </div>
  </c-side-panel-dialog>
</template>

<script>
import cSidePanelDialog from "./c-side-panel-dialog";
import cSelectableMenu from "./c-selectable-menu";
import cRepeatedDoseEntryPanel from "./c-repeated-dose-entry-panel";
import cSingleDoseEntryPanel from "./c-single-dose-entry-panel";
import cWeeklyDoseEntryPanel from "./c-weekly-dose-entry-panel";

import moment from 'moment-timezone';

export default {
  components: {
    cSidePanelDialog,
    cSelectableMenu,
    cRepeatedDoseEntryPanel,
    cSingleDoseEntryPanel,
    cWeeklyDoseEntryPanel,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    schedule: {
      type: Object,
      required: true,
    },
    timezone: {
      type: String,
      default: () => moment.tz.guess(),
      required: true,
    },
  },
  data() {
    const menuItems = [
        {label: "Repeated Dose", panel: "repeated-dose-panel"},
        {label: "Single Dose", panel: "single-dose-panel"},
        {label: "Weekly Schedule", panel: "weekly-dose-panel"},
      ];
    return {
      menuItems,
      currentSelectedMenuItem: menuItems[0],
    }
  },
  computed: {
    currentMenuTitle: function() {
      return (this.currentSelectedMenuItem) ? this.T(this.currentSelectedMenuItem.label) : "";
    },
  },
  methods: {
    showPanel(panel) {
      return this.currentSelectedMenuItem.panel === panel
    },
    onSelectedMenuItemsChanged(evt) {
      this.currentSelectedMenuItem = evt.new;
    },
    onSave(doses) {
      this.$emit('save', doses);
    },
    onCancel() {
      this.$bvModal.hide(this.id);
    }
  }
}
</script>
