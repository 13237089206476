<template>
  <b-container class="section">
    <b-row align-h="between">
      <b-col :class="'status ' + (contact.Status ? 'active' : 'inactive')" align-self="center" cols="2">
          <div>{{ contact.Status ? 'ACTIVE' : 'INACTIVE' }}</div>
      </b-col>
      <b-col cols="9">
        <b-row>
          <b-col>
            <b-card-body title="Contact Reference">
              <b-card-text>{{ contact.Ref }}</b-card-text>
            </b-card-body>
          </b-col>
          <b-col>
            <b-card-body title="Relationship">
              <b-card-text>{{ contact.Relation }}</b-card-text>
            </b-card-body>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-card-body title="Email">
              <b-card-text>{{ contact.Email }}</b-card-text>
            </b-card-body>
          </b-col>
          <b-col>
            <b-card-body title="Phone">
              <b-card-text>{{ contact.Phone }}</b-card-text>
            </b-card-body>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="1" align-self="center" class="icon">
        <b-row>
          <b-col>
              <font-awesome-icon :icon="['fas', 'eye']" @click="$emit('edit')" />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
              <font-awesome-icon :icon="['fas', 'trash']" @click="$emit('remove')" />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
              <font-awesome-icon :icon="['fas', 'phone']" :class="contact.Status ? 'inactive' : 'active'" @click="$emit('toggle')" />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script lang="js">
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPhone, faTrash, faEye } from '@fortawesome/free-solid-svg-icons';
library.add(faPhone, faTrash, faEye)
export default {
  props: {
    contact: {

    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/themes/branding.scss";

.status {
  font-size: 2em;
  text-transform: uppercase;
  text-align: center;
  transform: translate(-1rem)rotate(-90deg);
  border-bottom: 5px solid;
}

.active {
  color: green;
}

.inactive {
  color: var(--danger);
}

.section {
  background-color: white;
  padding: 1em 1em 1em 0em;
  border: 2px solid black;
  border-radius: 25px;
}

.spacer {
  height: 2em;
}

.icon {
  cursor: pointer;
  font-size: 1.5em;
  text-align: center;
  svg {
    margin: 0.5em;
  }
  svg:hover {
    opacity: 80%;
  }
}

.card-text {
  font-weight: bold;
}
</style>
