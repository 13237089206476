<template>
  <b-card class="section">
    <b-row no-gutters>
      <b-col md="11">
        <b-row>
          <b-col>
            <b-card-body title="Name">
              <b-card-text>{{ Name }}</b-card-text>
            </b-card-body>
          </b-col>
          <b-col>
            <b-card-body title="ID">
              <b-card-text>{{ Empid }}</b-card-text>
            </b-card-body>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-card-body title="Email">
              <b-card-text>{{ Email }}</b-card-text>
            </b-card-body>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="1">
        <b-row>
          <b-button variant="link" @click="editAdmin">
            <font-awesome-icon class='icon' :icon="['far', 'eye']" />
          </b-button>
        </b-row>
        <b-row>
          <b-button variant="link" @click="deleteYesNo">
            <font-awesome-icon class='icon' :icon="['fas', 'trash']" />
          </b-button>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
library.add(faEye, faTrash);

export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    Name() {
      if (this.value["Name"]) {
        return this.value["Name"];
      }
      return "";
    },
    Email() {
      if (this.value["Email"]) {
        return this.value["Email"];
      }
      return "";
    },
    Empid() {
      if (this.value["Empid"] && this.value["Empid"].length > 0) {
        return this.value["Empid"];
      }
      return "--";
    },
  },
  methods: {
    deleteYesNo() {
      this.msgBoxYesNo("Are you sure you want to delete this pharmacist?").then(
        (value) => {
          if (value) {
            this.$emit("delete", this.value);
          }
        }
      );
    },
    editAdmin() {
          this.$emit("edit", this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.section {
  border: 1px solid black;
  border-radius: 10px;
  padding: 5px;
}

button.btn-link {
  color: var(--secondary);
  box-shadow: none;
  font-size: 1.1em;
}

.card-body {
  padding: 5px;
}

.card-text {
  font-weight: bold;
}
</style>
