<template>
  <b-container class="cpax-notification-profile-listing-item">
    <b-row>
      <b-col class="label">
        {{ name }}
      </b-col>
      <b-col>
        <b-button variant="outline-primary" @click="onView">{{ T('View') }}</b-button>
        <b-button variant="outline-primary" @click="onDisable">{{ T(btnLabel) }}</b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    enabled: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    btnLabel() {
      return this.enabled ? 'Enabled' : 'Disable';
    }
  },
  methods: {
    onView: function(evt) {
      this.$emit('view', evt);
    },
    onDisable: function(evt) {
      this.$emit('disable', evt)
    }
  }
}
</script>

<style lang="scss" >
 .cpax-notification-profile-listing-item {
   margin: 10px;
   .label {
     text-align: right;
     margin: auto;
     vertical-align: middle;
   }
   button.btn-outline-primary,
   button.btn-outline-primary:not(:disabled) {
     margin: 2px 10px;
     color: var(--primary);
     padding: 10px;
   }
 }
</style>
