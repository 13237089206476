<template>
  <div>
    <c-wizard-dialog id="basic-information"
                     footer-class="button-bar"
                     no-back-btn
                     @next="next('technology')"
                     :title="bTitle + T('Basic Information')">
      <c-schedule-basic-information-form v-model="schedule" />
    </c-wizard-dialog>
    <c-wizard-dialog id="technology"
                     footer-class="button-bar"
                     @back="back()"
                     @next="next('schedule-type')"
                     :title="bTitle + T('Technology')">
      <c-wizard-radio-form v-model="schedule"
                           :options="technologyTypeOptions"
                           index="Technologies" />
    </c-wizard-dialog>

    <c-wizard-dialog id="schedule-type"
                     footer-class="button-bar"
                     @back="back()"
                     @next="next('blister-card-type')"
                     :title="bTitle + T('Technology')">
      <c-wizard-radio-form v-model="schedule"
                           :options="scheduleTypeOptions"
                           index="ScheduleType" />
    </c-wizard-dialog>


    <c-wizard-dialog id="blister-card-type"
                     footer-class="button-bar"
                     @back="back()"
                     @next="onBranchOnScheduleType"
                     :title="bTitle + T('Technology')">
      <c-wizard-radio-form v-model="schedule"
                           :options="cardTypeOptions"
                           index="CardType" />
    </c-wizard-dialog>

    <c-wizard-dialog id="prn-monitoring-period"
                     footer-class="button-bar"
                     @back="back()"
                     @next="next('prn-doses-limit-period')"
                     :title="bTitle + T('PRN Monitoring Period')" >
      <c-wizard-radio-form v-model="schedule"
                           :options="prnMonitoringPeriodOptions"
                           index="PRNMonitoringPeriod" />
    </c-wizard-dialog>

    <c-wizard-dialog id="prn-doses-limit-period"
                     footer-class="button-bar"
                     @back="back()"
                     @next="next('prn-notification-type')"
                     :title="bTitle + T('PRN Dose Limit Per Monitoring Period')" >

      <c-wizard-input-form v-model="schedule"
                           :placeholder="T('Dose Limit')"
                           index="PRNDoseLimitPeriod" />
    </c-wizard-dialog>

    <c-wizard-dialog id="prn-notification-type"
                     footer-class="button-bar"
                     @back="back()"
                     @next="onCardConfiguration"
                     :title="bTitle + T('PRN Notification Type')" >
      <c-wizard-radio-form v-model="schedule"
                           :options="prnNotificationTypeOptions"
                           index="PRNNotification" />
    </c-wizard-dialog>

    <c-wizard-dialog id="card-blister-config"
                     footer-class="button-bar"
                     skip-btn
                     :skip-title="T('Skip')"
                     @back="back()"
                     @next="next('video-instruction')"
                     :title="bTitle + T('Blister to Exclude (appear in grey)')" >
      <c-wizard-dose-grid :rows="7" :cols="4" v-model="schedule" index="CardConfiguration" />
    </c-wizard-dialog>

    <c-wizard-dialog id="card-total-doses"
                     footer-class="button-bar"
                     skip-btn
                     :skip="T('Skip (appllies cloned criteria)')"
                     @back="back()"
                     @next="next('video-instruction')"
                     :title="bTitle + T('Enter Number of Doses')"  >
      <c-wizard-input-form v-model="schedule"
                           :placeholder="T('Dose Count')"
                           index="DoseCount" />
    </c-wizard-dialog>

    <c-wizard-dialog id='video-instruction'
                     footer-class='button-bar'
                     skip-btn
                     :skip-title="T('Skip')"
                     @back="back()"
                     @next="onAssigned"
                     :title="bTitle + T('Video Instruction')" >
      <c-wizard-input-form v-model="schedule"
                           :placeholder="T('Video URL')"
                           index="videourl" >
        <b-embed v-if="schedule && schedule['videourl'] && schedule['videourl'] != ''"
                 type="iframe"
                 aspect="16by9"
                 :src="videolink"
                 allowfullscreen>
        </b-embed>
      </c-wizard-input-form>
    </c-wizard-dialog>

    <c-wizard-dialog id="assigned-eac"
                     footer-class='button-bar'
                     skip-btn
                     :skip-title="T('Assign Later')"
                     @back="back()"
                     @next="next('wizard-summary')"
                     :title="bTitle + T('Link Card Reader')" >
      <c-technology-eac-code-form v-model="schedule" index="EACCode" />
    </c-wizard-dialog>

    <c-wizard-dialog id="assigned-nfc"
                     footer-class='button-bar'
                     skip-btn
                     :skip-title="T('Assign Later')"
                     @back="back()"
                     @next="next('wizard-summary')"
                     :title="bTitle + T('Activate NFC Tag')" >
      <c-technology-nfc-code-form v-model="schedule" index="NFCCode" />
    </c-wizard-dialog>

    <c-wizard-dialog id='wizard-summary'
                     footer-class="button-bar"
                     @back="back()"
                     @next="onSave()"
                     :next-title="T('Create New Schedule')"
                     :title="bTitle + T('Schedule Summary')" >
      <c-wizard-summary v-model="schedule"
                        :technologyTypeOptions="technologyTypeOptions"
                        :scheduleTypeOptions="scheduleTypeOptions"
                        :cardTypeOptions="cardTypeOptions"
                        :prnMonitoringPeriodOptions="prnMonitoringPeriodOptions"
                        :prnNotificationTypeOptions="prnNotificationTypeOptions"
      />

    </c-wizard-dialog>
  </div>
</template>

<script>
import cWizardDialog from "./c-wizard-dialog";
import cScheduleBasicInformationForm from "./c-schedule-basic-information-form";
import cWizardRadioForm from "./c-wizard-radio-form";
import cWizardInputForm from "./c-wizard-input-form";
import cWizardDoseGrid from "./c-wizard-dose-grid";
import cWizardSummary from "./c-wizard-summary";
import cTechnologyEacCodeForm from "./c-technology-eac-code-form";
import cTechnologyNfcCodeForm from "./c-technology-nfc-code-form";

import { convertToEmbeddedURL } from "@/lib/url-translator"

export default {
  components: {
    cWizardDialog,
    cScheduleBasicInformationForm,
    cWizardRadioForm,
    cWizardInputForm,
    cWizardDoseGrid,
    cWizardSummary,
    cTechnologyNfcCodeForm,
    cTechnologyEacCodeForm
  },
  props: {
    patient: {
      type: Object,
      required: true,
    }
  },
  data() {
    const bTitle = this.T('New Schedule Wizard') + " - ";
    return {
      bTitle,
      schedule: {},
      stack: [],
      current: undefined,
      technologyTypeOptions: [
        { text: this.T('EAC'), value: 'eac' },
        { text: this.T('NFC'), value: 'nfc' },
      ],
      scheduleTypeOptions: [
        { text: this.T('Non-PRN'), value: 'multidose'},
        { text: this.T('PRN'), value: 'unitdose', disabled: false },
      ],
      cardTypeOptions: [
        { text: this.T('28-Cavity Weekly Card'), value: 'weekly28'},
        { text: this.T('28-Cavity Unit Card'), value: 'unit28'},
        { text: this.T('Non-card'), value: 'nocard', disabled: false},
      ],
      prnMonitoringPeriodOptions: [
        { text: this.T('Daily'), value:  'daily' },
        { text: this.T('Weekly'), value: 'weekly' },
        { text: this.T('Montly'), value: 'monthly' },
        { text: this.T('Custom'), value: 'custom' },
      ],
      prnNotificationTypeOptions: [
        { text: this.T('Warning'), value: 'warning' },
        { text: this.T('Overdose'), value: 'overdose' },
        { text: this.T('Warning and Overdose'), value: 'both'}
      ],
    }
  },
  computed: {
    valid() {
      return this.schedule.valid ? true : false;
    },
    videolink() {
        return convertToEmbeddedURL(this.schedule['videourl']);
    }
  },
  methods: {
    start: function() {
      this.schedule = {}
      this.next("basic-information");
    },
    next: function(id) {
      if (this.current) {
        this.stack.push(this.current);
      }
      this.current = id
      this.$bvModal.show(this.current);
    },
    back: function() {
      this.current = this.stack.pop();
      if (this.current && this.current != '') {
        this.$bvModal.show(this.current);
      }
    },
    onBranchOnScheduleType() {
      if (this.schedule['ScheduleType'] === this.scheduleTypeOptions[1]) {
        // take the wizard on the prn branch
        this.next('prn-monitoring-period');
      } else {
        this.onCardConfiguration()
      }
    },
    onCardConfiguration() {
      if (this.schedule['CardType'] == 'nocard') {
        this.next('card-total-doses')
      } else {
        this.next('card-blister-config')
      }
    },
    onAssigned() {
      if (this.schedule['Technologies'] === 'nfc') {
        this.next('assigned-nfc');
      } else {
        this.next('assigned-eac');
      }
    },
    onSave() {
      // need to fix the videourls to be an array.
      this.schedule['PatientID'] = this.patient.ID;
      this.schedule['VideoURLs'] = [this.schedule['videourl']];
      const _vm = this;
      this.$store.dispatch("schedules/storeSchedule", this.schedule)
          .catch((err) => {
            console.log(err);
            _vm.notificationDialog(_vm.T('Error') + ': ' + _vm.T('Saving new schedule'))
                .then(() => {
                 _vm.next("basic-information");
                });
          });
    },
  }
}
</script>
