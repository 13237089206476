<template>
  <b-container fluid class="patients">
    <b-row>
      <b-col>
        <c-adherence-bar />
      </b-col>
    </b-row>
    <b-row>
      <b-col class="section-header">
        <div>{{T("Active Schedules")}}</div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <c-schedule-list
          :dataset="schedules"
          class="schedule-list"
          :selected="schedule"
          @select="onSelectSchedule"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col class="section-header">
       {{T('Currently Viewing')}}
       <span class="viewing-section">{{viewingSection}}</span>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div class="schedule-menu">
          <b-button v-b-modal.cpax-schedule-add-dose-dialog>{{T('Add Dose')}}</b-button>
          <c-schedule-add-dose-dialog id="cpax-schedule-add-dose-dialog"
                                      :schedule="schedule"
                                      :timezone="timezone"
                                      @save="onCreateDoses" />

          <b-button v-b-modal.cpax-add-modify-video-dialog :disabled="schedule === null">
            {{T('Add/Modify Video')}}
          </b-button>
          <c-add-modify-video-dialog
              id="cpax-add-modify-video-dialog"
              :url="currentScheduleVideoURL"
              @update="onUpstateScheduleVideoURL" />

          <b-button v-b-modal.cpax-assign-package-dialog>{{T('Assign Package')}}</b-button>
          <c-assign-schedule-to-technology id="cpax-assign-package-dialog" :schedule="schedule" />

          <b-button v-b-modal.cpax-clone-schedule>{{T('Duplicate Schedule')}}</b-button>
          <c-clone-schedule-dialog
            id="cpax-clone-schedule"
            :schedule="schedule"
            :timezone="timezone"
            @clone="onCloneSchedule" />

          <b-button v-b-modal.cpax-schedule-notification>{{T('Notification Centre')}}</b-button>
          <c-schedule-notification-dialog
            id="cpax-schedule-notification"
            :schedule="schedule"
            @save="onUpdateScheduleNotification" />

          <b-button v-b-modal.cpax-report-generator-dialog>{{T('Generate Report')}}</b-button>
          <c-report-generator-dialog id="cpax-report-generator-dialog" :dataset="schedules" />

          <b-button id="cpax-schedule-delete"
                    variant="danger"
                    @click="onScheduleDeleteConfirmation">
            {{T('Delete Schedule')}}
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-row class="pt-4">
      <b-col>
        <b-container fluid>
          <b-row>
            <b-col>
              <b-card no-body>
                <b-tabs card nav-class="patient-schedules">
                  <b-tab title="Calendar View">
                    <c-calendar-view :schedule="schedule" :timezone="timezone" :doses="viewDoses" />
                  </b-tab>
                  <b-tab title="Card View">
                    <b-card-body>
                      <c-card-view cols="4" rows="7" :doses="viewDoses" />
                    </b-card-body>
                  </b-tab>
                </b-tabs>
              </b-card>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState } from "vuex";
import cAdherenceBar from "@/components/c-adherence-bar";
import cScheduleList from "@/components/c-schedule-list";
import cCardView from "@/components/c-card-view";
import cCalendarView from "@/components/c-calendar-view";
import cAssignScheduleToTechnology from "@/components/c-assign-schedule-to-technology";
import cScheduleAddDoseDialog from "@/components/c-schedule-add-dose-dialog";
import cAddModifyVideoDialog from "@/components/c-add-modify-video-dialog";
import { getDoseStatusClass } from "@/lib/doses.js";
import moment from "moment-timezone";
import CReportGeneratorDialog from "@/components/c-report-generator-dialog.vue";
import CCloneScheduleDialog from "@/components/c-clone-schedule-dialog.vue";
import CScheduleNotificationDialog from "@/components/c-schedule-notification-dialog.vue";


//import { Schedule } from '@/models';

export default {
  name: 'viewPatient',
  components: {
    cScheduleList,
    cAdherenceBar,
    cCardView,
    cCalendarView,
    cAssignScheduleToTechnology,
    cScheduleAddDoseDialog,
    cAddModifyVideoDialog,
    CReportGeneratorDialog,
    CCloneScheduleDialog,
    CScheduleNotificationDialog,
  },
  props: {
    pid: {
      required: true,
    },
  },
  computed: {
    ...mapState({ patient: (state) => state.patients.selectedPatient || {} }),
    ...mapState({ schedules: (state) => state.schedules.schedules }),
    ...mapState({ schedule: (state) => state.schedules.selectedSchedule }),
    ...mapState({ doses: (state) => state.doses.doses }),
    ...mapState({ reporturl: (state) => state.patients.reporturl }),
    viewingSection() {
      if (this.schedule) {
        return this.schedule.Description
      }
      return ""
    },
    timezone() {
      return (this.patient && this.patient.Timezone)  || moment.tz.guess();
    },
    viewDoses() {
      return this.doses.map((dose) => {
        let e = moment
          .tz(dose.Intake, this.timezone)
          .add(dose.Range, "h")
          .format("YYYY-MM-DD HH:mm");

        let r = dose.Range || 1;

        let s = moment
          .tz(dose.Intake, this.timezone)
          .subtract(r, "h")
          .format("YYYY-MM-DD HH:mm");

        let taken =
          dose.Status == "Open"
            ? moment.tz(dose.Taken, this.timezone).format("YYYY-MM-DD HH:mm")
            : "";

        let blisterIdx =
          dose.BlisterIndex == 0
            ? this.T("Blister")
            : `${this.T("Blister")}  ${dose.BlisterIndex}`;

        let title =
          dose.Status == "Close"
            ? blisterIdx
            : `${this.T("taken")} @ ${moment(dose.Taken).format("HH:mm")}`;

        let status = getDoseStatusClass(dose);

        return {
          dose: dose,
          taken: taken,
          blister: blisterIdx,
          start: s,
          end: e,
          title: title,
          class: status,
        };
      });
    },
    currentScheduleVideoURL() {
        let url = '';
        if (Array.isArray(this.schedule.VideoURLs) && (this.schedule.VideoURLs.length > 0)) {
            url = this.schedule.VideoURLs[0];
        }
        return url;
    }
  },
  methods: {
    selectSchedule(id) {
      this.$store.commit("schedules/setSelectedSchedule", id);
      this.$store.dispatch("doses/getDoses", id);
    },
    onSaveSchedule(schedule) {
      this.$store.dispatch("schedules/storeSchedule", schedule);
    },
    onScheduleDeleteConfirmation() {
      const _vm = this;
      this.confirmationDialog(this.T("Are you sure you want to delete the selected schedule."), '', {
        okTitle: _vm.T("Yes"),
        cancelTitle: _vm.T("Cancel"),
      }).then((value) => {
        if (value) {
          _vm.$store.dispatch("schedules/removeSchedule", _vm.schedule).catch(() => {
            _vm.notificationDialog(_vm.T("Error"), _vm.T("can't remove selected schedule."));
          });
        }
      });
    },
    onUpstateScheduleVideoURL(url) {
      let s = Object.assign({}, this.schedule);
      if (Array.isArray(s.VideoURLs)) {
        s.VideoURLs[0] = url;
      } else {
        s.VideoURLs = [url];
      }
      this.$store.dispatch("schedules/storeSchedule", s).catch((e) => {
        console.log(e)
        this.notificationDialog(this.T('Error'), this.T("can't update schedule video url."))
      });
    },
    onSelectSchedule(evt) {
      this.selectSchedule(evt.ID);
    },
    onCloneSchedule: function (schedule) {
      this.$store.dispatch("schedules/clone", schedule).catch((e) => {
        console.log(e);
        this.notificationDialog(this.T('Error'), this.T("can't clone schedule."));
      });
    },
    onUpdateScheduleNotification: function(notification) {
      let s = Object.assign({}, this.schedule);
      s.Notification = notification;
      console.log(s);
      this.$store.dispatch("schedules/storeSchedule", s).catch((e) => {
        console.log(e);
        this.notificationDialog(this.T('Error'), this.T("can't update schedule notification."));
      });
    },
    onCreateDoses: function(doses) {
      let s = this.schedule;
      let _vm = this;
      doses.forEach(function(dose) {
        dose.PatientID = s.PatientID;
        dose.ScheduleID = s.ID;
        try {
          _vm.$store.dispatch("doses/createDose", dose);
        } catch(error) {
          this.notificationDialog(_vm.T('Error'), error.message);
          console.log(error);
        }
      });
      this.$bvModal.hide("cpax-schedule-add-dose-dialog");
    },
    getPatientInfo(pid) {
      this.$store.dispatch('patients/setSelectedPatient', pid).then(() => {
        this.$store.dispatch('schedules/getSchedules').then(() => {
          // fetch the schedule for the selected Patient
          if (this.schedule.length > 0) {
            this.selectSchedule(this.schedules[0].ID);
          } else {
            // clear any dose if the patient dose not have a schedule.
            this.$store.commit("doses/setDoses", [])
          }
        });
      });
    }
  },
  mounted: function() {
    this.getPatientInfo(this.pid);
  },
  watch: {
    piD: function (pid) {
      this.getPatientInfo(pid);
    },
  },
};
</script>

<style lang="scss" scoped>
.patients {
  padding: 0;
}
.schedule-list {
  scroll: auto;
  max-height: 400px;
}
 .section-header {
   padding-left: 30px;
   padding-right: 30px;
   font-family: var(--cpax-font-header);
   font-size: 32px;
   font-weight: 300;
 }

 .viewing-section {
   font-weight: 700;
 }
 .schedule-menu {
   text-align: center;
   .btn {
     font-family: var(--font-brand-schedule-menu);
     font-size: 12px;
     padding: 4px 7px;
     margin: 0px 2px;
     white-space: nowrap;
     border-radius: 3px;
     box-shadow: var(--cpax-box-shadow);
   }
 }
</style>

<style lang="scss">
 .patient-schedules {
   a.nav-link {
     font-weight: bold;
     color: var(--primary);
     &.active {
       color: black;
     }
   }
 }
</style>
