<template>
  <b-container v-if="show" class="dose-card" :class="adherenceStatus">
    <b-col>
      <b-container fluid>
        <b-row>
          <b-col>
            <h2>Details</h2>
            <div class="text">{{ details }}</div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h2>Time &amp; Date</h2>
            <div class="text">{{ intake }}</div>
          </b-col>
        </b-row>
        <b-row>
          <b-col v-if="status">
            <h2>Adherence Status</h2>
            <div class="text">{{ status }}</div>
          </b-col>
          <b-col v-else>
            <div class="text">Upcomming</div>
          </b-col>
        </b-row>
      </b-container>
    </b-col>
    <b-col cols="auto" align-self="center">
      <div class="search" :class="adherenceStatus">
        <font-awesome-icon :icon="['fas', 'search']" />
      </div>
    </b-col>
  </b-container>
</template>

<script>
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
library.add(faSearch);

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    show () {
      return (this.dose);
    },
    dose() {
      return (this.item && this.item.dose);
    },
    details() {
      return (this.dose && this.dose.Title) || "n/a";
    },
    intake() {
      return (this.dose && this.dose.Intake) || "n/a";
    },
    status() {
      return (this.dose && this.dose.Status) || "n/a";
    },
    adherenceStatus() {
      return (this.dose && this.item.class) || "blank";
    },
  },
};
</script>

<style lang="scss" scoped>

  .row {
   padding: 10px;
 }

 h2 {
   font-weight: normal;
   font-size: 14px;
 }

 .text {
   font-weight: bolder;
 }

 .dose-card {
   margin: 20px;
   display: flex;
   border-radius: 20px;
   &.Late {
     background-color: var(--dose-late);
     color: var(--dose-late-accent);
   }

   &.Overdose {
     background-color: var(--dose-overdose);
     color: var(--dose-overdose-accent);
   }

   &.Upcoming {
     background-color: var(--dose-upcoming);
     color: var(--dose-upcoming-accent);
   }
   &.Overdue {
     background-color: var(--dose-overdue);
     color: var(--dose-overdue-accent);
   }

   &.Ok {
     background-color: var(--dose-good);
     color: var(--dose-good-accent);
   }
 }

 .search {
   text-align: right;
   display: flex;
   border: none;
   border-radius: 20px;
   text-align: center;
   align-items: center;
   margin: auto;
   justify-content: center;
   min-height: 4em;
   color: white;
   box-shadow: var(--cpax-box-shadow);
   width: 4em;
   &.Late {
     background-color: var(--dose-late-accent);
   }

   &.Overdose {
     background-color: var(--dose-overdose-accent);
   }

   &.Upcoming {
     background-color: var(--dose-upcoming-accent);
   }

   &.Overdue {
     background-color: var(--dose-overdue-accent);
   }

   &.Ok {
     background-color: var(--dose-good-accent);
   }


 }


</style>
