<template>
  <b-container fluid class='cpax-schedule-radio-form'>
    <b-row class="cpax-row" v-for="option in options" :key="option.key">
      <b-col>
        <b-form-radio v-model="selected"
                      :key="option.value"
                      :value="option.value"
                      @change="update"
                      :disabled="option.disabled"
                      :invalid-feedback="T(v$.selected.$error[0])"
                      :state="vuelidateState(v$.selected)"
                      name="selected"
                      button
                      button-variant="outline-secondary">
          {{ option.text }}
        </b-form-radio>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { vuelidateState } from "@/lib/vuelidate-helper";
import { required } from "@vuelidate/validators";

export default {
  setup: () => ({
    v$: useVuelidate(),
    vuelidateState,
  }),
  props: {
    value: {
      type: Object,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    index: {
      type: String,
      require: true,
    },
    rules: {
      type: Object,
      default: () => ({ required }),
    },
  },
  data() {
    return {
      selected: '',
    }
  },
  validations() {
    return {
      selected: this.rules
    }
  },
  methods: {
    update: function() {
      this.v$.$validate().then((v) => {
        if (v) {
          let s = {};
          s[this.index] = this.selected;
          this.$emit('input', Object.assign({}, this.value, s))
        }
      });
    }
  },
  mounted() {
    const v = this.value[this.index];
    if (v) {
      this.selected = v;
    } else {
      this.$emit('input', Object.assign({}, this.value, {valid: false}));
    }
  },
}
</script>

<style lang="scss" scoped>
 .cpax-row {
   padding: 10px;
   align-content: center;
   text-align: center;
 }
</style>

<style lang="scss">
.cpax-schedule-radio-form {
  .btn-group-toggle {
    display: unset !important;
  }
  .btn {
    min-width: 100px;
    width: 50%;
  }
 }
</style>
