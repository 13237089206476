<template>
    <c-wizard-input-form v-model="dataset" :placeholder="T('Device Serial Number')" :index="index">
        <div class="instructions">
            <p>
                Enter the serial number found on the back of your card reader
                as highlighted below. Enter the entire portion highlighted in red
            </p>
            <p>
                <span class="sn-box">SN</span>
                <span class="sn-code">DCAXXX</span>
            </p>
        </div>
    </c-wizard-input-form>
</template>

<script>
import cWizardInputForm from "./c-wizard-input-form";

export default {
    components: {
        cWizardInputForm,
    },
    props: {
        value: {
            type: Object,
            required: true,
        },
        index: {
            type: String,
            required: true,
        }
    },
    data() {
        return {
            dataset: Object.assign({}, this.value)
        };
    },
    watch: {
        dataset: function (v) {
          console.log(v)
            if (v[this.index] !== this.value[this.index]) {
                const d = Object.assign({}, this.value)
            d[this.index] = v[this.index]
            this.$emit('input', d)

            }
        }
    }
}
</script>

<style lang="scss">
.instructions {
    text-align: center;
    margin: auto;
    width: 70%;
    font-family: var(--font-brand-normal);
    font-size: 20px;
    p {
        padding: 1rem 1rem;
    }
    .sn-box {
        font-weight: bold;
        border: 2px solid black;
        padding: 3px 10px;
    }
    .sn-code {
        font-family: var(--font-brand-normal);
        font-weight: bold;
        color: var(--danger);
        padding: 3px 10px;
    }
}
</style>
