<template>
  <c-side-panel-dialog
    :id="id"
    :title="T(title)"
    @shown="updateList"
  >
    <template #sidepanel>
      <c-selectable-menu class="menu" :items="anomalyTypeMenuItems" :title="T('Anomaly Type')" />
      <c-selectable-menu class="menu" :items="dateRangeMenuItems" :title="T('Date Range')" />
    </template>

    <b-container fluid>
        <b-row v-for="s in anomalyList" :key="s.ID">
          <b-col>
            <c-dose-status-card
                :patientRef="s.Name"
                :scheduleType="s.scheduleType"
                :anomaly="getAnomalyText(s.Anomaly)"
                :adherenceStatus="s.Anomaly"
            />
          </b-col>
        </b-row>
    </b-container>
  </c-side-panel-dialog>
</template>

<script>
//import { mapState } from 'vuex';
import cDoseStatusCard from "@/components/c-dose-status-card";
import cSidePanelDialog from "./c-side-panel-dialog";
import cSelectableMenu from "./c-selectable-menu";

export default {
  components: { cDoseStatusCard, cSidePanelDialog, cSelectableMenu },
  props: {
    id: {
      required: true,
    },
    title: {
      default: "Missed Dose Report",
    },
  },
  data() {
    return {
      anomalyTypeMenuItems: [
        {key: 0, label: "All anomalies"},
        {key: 1, label: "Not yet taken anomalies"},
        {key: 2, label: "Mised anomalies"},
        {key: 3, label: "Overdose anomalies"},
      ],
      dateRangeMenuItems: [
        {key: 0, label: "7-days"},
        {key: 1, label: "1-month"},
        {key: 2, label: "1-year"},
        {key: 3, label: "Custom"},
      ]
    }
  },
  computed: {
    anomalyList() {
      return [
        { Name: "John Doe", scheduleType: "Regular", Anomaly: "nostatus" },
        { Name: "John Doe", scheduleType: "Regular", Anomaly: "overdose" },
        { Name: "John Doe", scheduleType: "Regular", Anomaly: "missed" },
        { Name: "John Doe", scheduleType: "Regular", Anomaly: "ontime" },
        { Name: "John Doe", scheduleType: "Regular", Anomaly: "overdose" },
        { Name: "John Doe", scheduleType: "Regular", Anomaly: "missed" },
      ];
    },
    //...mapState('patients', ['anomalyList'])
  },
  methods: {
    mailto: function (email) {
      return `mailto://${email}`;
    },
    updateList() {
      this.$store.dispatch("patients/updateAnomalyList");
    },
      getAnomalyText(v) {
          return v
    }
  },
};
</script>

<style lang="scss" scoped>

 .menu {
   padding-bottom: 30px;
 }
</style>
