<template>
  <c-simple-dialog :id="id"
                   dialog-class="cpax-patient-setting-dialog"
                   :title="title"
                   :ok-title="T('Save')"
                   @cancel="onCancel"
                   @ok="onSave">

    <template v-if="patient" #button-bar>
      <b-button variant="danger"
                sm="sm"
                @click="onRemovePatient">
        {{T('Delete Patient')}}
      </b-button>
    </template>
    <c-patient-general-settings-form  v-model="dataset" />
  </c-simple-dialog>
</template>

<script>
import cSimpleDialog from "./c-simple-dialog";
import cPatientGeneralSettingsForm from "./c-patient-general-setting-form";

import useVuelidate from "@vuelidate/core";

export default {
  setup: () => ({
    v$: useVuelidate(),
  }),
  components: {
    cSimpleDialog,
    cPatientGeneralSettingsForm,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    patient: {
      type: Object,
      default: () => undefined,
    },
  },
  data: function() {
    return {
      dataset: {}
    }
  },
  methods: {
    onSave(evt) {
      // need to validate dataset
      evt.preventDefault();
      const vm = this;
      this.v$.$validate().then((v) => {
        if (v) {
          vm.$store.dispatch("patients/storePatient", vm.dataset).then(() => {
            if (vm.patient === undefined) {
              this.reset();
            }
            vm.$bvModal.hide(vm.id);
          }).catch((e) => {
            console.log(e);
            vm.notificationDialog(vm.T("Error"), vm.T("unable to save patient."));
          });
        }
      });
    },
    onRemovePatient() {
      const _vm = this;
      this.confirmationDialog(this.T("Are you sure you want to delete this patient."), '', {
        okTitle: _vm.T("Yes"),
        cancelTitle: _vm.T("Cancel"),
      }).then((value) => {
        if (value) {
          _vm.$store.dispatch("patients/removePatient", _vm.patient).then((result) => {
            console.log(result);
            _vm.$router.push({ name: "patientList" });
          }).catch(() => {
            _vm.notificationDialog(_vm.T("Error"), _vm.T("can't remove this patient"));
          });
        }
      });
    },
    onCancel() {
      this.dataset = Object.assign({}, this.patient);
    },
    reset() {
      this.dataset = {};
    }
  },
  watch: {
    patient: function(patient) {
      this.dataset = Object.assign({}, patient);
    }
  },
  mounted() {
    console.log("patient", this.patient);
    this.dataset = Object.assign({}, this.patient);
  }
}
</script>

<style lang="scss" scoped>
 .form-row {
   margin-bottom: 20px;
 }
 .button-bar {
   display: flex;
   justify-content: space-between;
 }
</style>

<style lang="scss" >
 .cpax-patient-setting-dialog {
   label.form-control {
     &.is-invalid {
       background-image: unset;
     }
     &.is-valid {
       background-image: unset;
     }
   }
   .modal-footer {
     justify-content: space-between;
   }
 }

</style>
