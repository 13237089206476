<template>
    <b-modal :id="id" :dialog-class="[dialogClass, 'cpax-simple-dialog']" :content-class="contentClass" size="xl"
        @ok="onOk" @keyup.enter="onOk" @cancel="onCancel" :footer-class="footerClass">
        <template #modal-header="{ close }">
            <h5 class="modal-title">{{T(title)}}</h5>
            <button type="button" class="close" @click="close()">
                <font-awesome-icon :icon="['far', 'circle-xmark']" />
            </button>
        </template>
        <slot />

        <template #modal-footer="{ ok, cancel }">
            <b-button v-if="!noCancelBtn" size="sm" variant="secondary" @keyup.esc="cancel" @click="cancel">
                {{ T('Cancel') }}
            </b-button>
            <div class="right-section">
                <slot name="button-bar" />
                <b-button size="sm" variant="primary" @click="ok">
                    {{ okTitle }}
                </b-button>
            </div>
        </template>
    </b-modal>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
library.add(faCircleXmark);

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    dialogClass: {
      type: String,
      default: "",
    },
    okTitle: {
      type: String,
      default: "Save",
    },
    id: {
      type: String,
      required: true,
    },
    footerClass: {
      type: String,
      default: 'cpax-simple-dialog-footer',
    },
    contentClass: {
      type: String,
      default: 'cpax-simple-dialog-content',
    },
    noCancelBtn: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    onOk: function (bvModalEvt) {
      this.$emit("ok", bvModalEvt);
    },
    onCancel: function (bvModalEvt) {
      this.$emit("cancel", bvModalEvt);
    }
  },
};
</script>

<style lang="scss" scoped>
.modal-title {
  font-family: var(--font-brand-normal);
  font-size: 24px;
  font-weight: 700;
}

.close {
  font-weight: 700;
  font-size: 24px;
  opacity: 0.75;
}

.close:not(:disabled):not(.disabled):hover {
  opacity: 1;
}

 .right-section {
   display: flex;
   .btn {
     margin-left: 20px;
   }
 }
</style>

<style lang="scss" >
.cpax-simple-dialog {
  .modal-header {
    border-bottom: none;
  }
  .modal-footer {
    border-top: none;
  }
}
</style>
