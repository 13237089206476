<template>
    <b-form-input :id="id" type="time" v-model="textTime" @change="onChanged" :state="state" />
</template>

<script>

export default {
    props: {
        id: {
            type: String,
            default: 'cpax-input-timepicker',
        },
        value: {
            type: String,
            required: true
        },
        state: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            textTime: this.value,
        }
    },
    methods: {
        onChanged() {
            this.$emit('input', this.textTime)
            this.$emit('change', this.textTime)
        }
    },
}
</script>
