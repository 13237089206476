<template>
  <b-container class="cpax-single-dose-entry-panel">
    <b-row class="form-row">
      <b-col>
        <span class="label">{{T('Date')}}</span>
      </b-col>
      <b-col>
        <c-datepicker id="dose-date"
                      v-model="v$.doseDate.$model"
                      :state="vuelidateState(v$.doseDate)" />
      </b-col>
    </b-row>
    <b-row class="form-row">
      <b-col>
        <span class="label">{{ T('Time')}}</span>
      </b-col>
      <b-col>
        <c-timepicker id="intake-time"
                      v-model="time"
                      :state="vuelidateState(v$.time)" />
      </b-col>
    </b-row>
    <b-row class="form-row">
      <b-col>
        <span class="label">{{T('Intake Range')}}</span>
      </b-col>
      <b-col>
        <b-input-group :append="T('Minutes')">
          <b-form-input id="intake"
                        type="number"
                        number
                        :state="vuelidateState(v$.intake)"
                        v-model="v$.intake.$model"
          />
        </b-input-group>
      </b-col>
    </b-row>
    <b-row class="form-row">
      <b-col>
        <span class="label">{{T('Description')}}</span>
      </b-col>
      <b-col>
        <b-form-textarea id="description"
                         v-model="v$.description.$model"
                         :state="vuelidateState(v$.description)"
                         :placeholder="T('Description')"
                         rows="4" >
        </b-form-textarea>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="button-bar">
        <b-button @click="onCancel" >{{ T("Cancel") }}</b-button>
        <b-button variant="primary" @click="onSave" >{{ T("Save") }}</b-button>
      </b-col>
   </b-row>
  </b-container>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { vuelidateState } from "@/lib/vuelidate-helper";

import { startOfDay, toTimePickerTime, generateSingleDose } from "@/lib/doses";
import { required, minValue } from "@vuelidate/validators";

import moment from 'moment-timezone';

import cDatepicker from "./c-datepicker";
import cTimepicker from "./c-timepicker";

export default {
  components: {
    cDatepicker,
    cTimepicker,
  },
  setup() {
    return {
      v$: useVuelidate(),
      vuelidateState,
    }
  },
  props: {
    schedule: {
      type: Object,
      required: true,
    },
    timezone: {
      type: String,
      default: ()=> moment.tz.guess(),
      required: true,
    },
  },
  data() {
    const currentTime = toTimePickerTime(new Date(), this.timezone);
    return {
      doseDate: moment(this.schedule.StartDate).toDate(),
      time: currentTime,
      intake: 60,
      description: "",
    }
  },
  validations() {
    return {
      doseDate: {
        required,
        minValueOfSchedule: minValue(
          startOfDay(this.schedule.StartDate, this.timezone).toDate())
      },
      time: {
        required,
      },
      intake: {
        required,
      },
      description: {
        required,
      }
    }
  },
  methods: {
    onSave() {
      this.v$.$validate().then((v) =>  {
        if (v) {
          const doses = generateSingleDose(this.doseDate,
                                           this.time,
                                           this.timezone,
                                           this.intake,
                                           this.description);
          this.$emit('save', doses);
        }
      });
    },
    onCancel() {
      this.$emit('cancel');
    }
  }
}
</script>

<style lang="scss" scoped>
 .form-row {
   margin-bottom: 20px;
 }
 .button-bar {
   display: flex;
   justify-content: space-between;
 }

</style>

<style lang="scss" >
 .cpax-repeated-dose-entry-panel {
   label.form-control {
     &.is-invalid {
       background-image: unset;
     }
     &.is-valid {
       background-image: unset;
     }
   }
 }
</style>
