 <template>
  <b-container fluid class="cpax-adherence-bar">
    <b-row class="bg-menu">
      <b-col align-self="center">
        <b-container fluid class="adherence-score">
          <b-row>
            <b-col md="auto">
              <span class="patient-name">{{ patient && patient.Name | defaultValue('(n/a)') }}</span>
            </b-col>
            <b-col md="auto">
              <span
                class="adherence-avg text-center"
                :class="statusColor"
              >{{T('Average Adherence Score')}}: {{overallAdherence | round }}%</span>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
      <b-col cols="auto">
        <b-container>
          <b-row>
            <b-col md="auto" class="p-2">
              <b-btn class="bar-btn" @click="startScheduleWizard()">
                <span>{{ T('Create New Schedule')}}</span>
              </b-btn>
              <c-schedule-wizard ref="scheduleWizard" :patient="patient" />
            </b-col>

            <b-col md="auto" class="p-2">
              <b-btn id="cpax-contact-address-book" class="bar-btn" v-b-modal.cpax-contact-address-book-dialog>
                <span>{{ T('Contact Address Book') }}</span>
              </b-btn>
              <c-contact-address-book-dialog
                id="cpax-contact-address-book-dialog"
                :title="T('Contact Address Book')"
              />
            </b-col>

            <b-col md="auto" class="p-2">
              <b-btn id="cpax-patient-setting" class="bar-btn" v-b-modal.cpax-patient-setting-dialog>
                <span>{{ T('Settings')}}</span>
              </b-btn>
              <c-patient-settings-dialog
                id="cpax-patient-setting-dialog"
                :title="T('Patient Settings')"
                :patient="patient"
              />
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script lang="js">
import { mapState } from "vuex";
import moment from "moment-timezone";

import cContactAddressBookDialog from "./c-contact-address-book-dialog";
import cPatientSettingsDialog from './c-patient-settings-dialog';
import cScheduleWizard from './c-schedule-wizard';

export default {
  components: {
    cContactAddressBookDialog,
    cPatientSettingsDialog,
    cScheduleWizard,
  },
  data() {
    return {
      newSchedule: {}
    }
  },
  computed: {
    ...mapState({"schedule": state => state.schedules.selectedSchedule}),
    ...mapState({ patient: (state) => state.patients.selectedPatient || {} }),
    ...mapState({"overallAdherence": state => state.patients.overallAdherence}),
    statusColor() {
      if (this.overallAdherence > 80) {
        return { "status-good": true }
      } else if (this.overallAdherence > 60) {
        return { "status-avg": true}
      }
      return {"status-bad": true}
    },
    currentPatientTimeZone() {
      if (this.patient) {
        return this.patient.timeZone;
      }
      return moment.tz.guess();
    },
  },
  methods: {
    startScheduleWizard() {
      this.$refs.scheduleWizard.start();
    },
  },
  filters: {
    round: function(value) {
      if (!isNaN(parseFloat(value)) && isFinite(value))
        return Math.round(value * 100) / 100.0
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.cpax-adherence-bar {
  background-color: var(--menu);
  font-family: var(--font-banner);
  font-weight: bold;

  .adherence-avg {
    box-shadow: var(--cpax-box-shadow);
    padding: 0.5rem 1rem;
    border-radius: var(--cpax-btn-radius);
  }

  .status-good {
    background-color: var(--adherence-good);
  }
  .status-avg {
    background-color: var(--adherence-avg);
  }
  .status-bad {
    background-color: var(--adherence-bad);
  }
}
</style>
