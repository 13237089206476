<template>
  <b-navbar class="cpax-nav-bar" toggleable="md" type="dark" active-class="active">
    <b-navbar-brand>
      <img src="@/assets/logo-branding-small.svg" class="logo d-inline-block" alt />
    </b-navbar-brand>
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item>
          <b-button id="c-add-patient" variant="outline-primary" v-b-modal.addPatientDialog>
            <span>{{ T('Add Patient') }}</span>
          </b-button>
          <c-patient-settings-dialog id="addPatientDialog" :title="T('Patient Settings Dialog')" />
        </b-nav-item>
        <b-nav-item>
          <b-button id="c-view-patient" variant="outline-primary" @click="onViewPatients">
            <span>{{ T('View Patients') }}</span>
          </b-button>
        </b-nav-item>
        <b-nav-item self-align="center">
          <b-button id="c-anomaly-dialog" variant="outline-primary" v-b-modal.anomalyReportDialog>
            <span>{{ T('Intervention Report')}}</span>
          </b-button>
          <c-intervention-report-list id="anomalyReportDialog" />
        </b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto">
        <b-nav-item>
          <b-button id="c-control-panel" variant="outline-primary" v-b-modal.controlPanelDialog>
            <span>{{ T('Control Panel') }}</span>
          </b-button>
          <c-control-panel-dialog id="controlPanelDialog" />
        </b-nav-item>
        <b-nav-item>
          <b-button id="c-change-password" variant="outline-primary" v-b-modal.changePasswordDialog>
            <span>{{ T('Change Password') }}</span>
          </b-button>
          <c-change-password-dialog id="changePasswordDialog" />
        </b-nav-item>
        <b-nav-item>
          <b-button id="c-logout" class="logout" @click="onLogout">
            <font-awesome-icon :icon="['fas', 'power-off']" />
          </b-button>
        </b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import { mapActions } from "vuex";

import cPatientSettingsDialog from "@/components/c-patient-settings-dialog";
import cChangePasswordDialog from "@/components/c-change-password-dialog";
import cInterventionReportList from "./c-intervention-report-list";
import cControlPanelDialog from "./c-control-panel-dialog";

import { library } from '@fortawesome/fontawesome-svg-core';
import { faPowerOff } from '@fortawesome/free-solid-svg-icons';
library.add(faPowerOff);

export default {
  props: ["page"],
  components: {
    cPatientSettingsDialog,
    cChangePasswordDialog,
    cInterventionReportList,
    cControlPanelDialog },
  methods: {
    ...mapActions(["logout"]),
    onViewPatients: function () {
      this.$router.push({ name: "patientList" });
    },
    onLogout: function () {
      const _vm = this;
      this.confirmationDialog(this.T("Are you sure you want to logout?"), '', {
          okTitle: this.T("OK"),
          cancelTitle: this.T("Cancel"),
        })
        .then((value) => {
          if (value) {
            _vm.logout();
            _vm.$router.push({ name: "login" });
          }
        });
    },
  },
};
</script>

<style lang="scss">
 .cpax-nav-bar {
   width: 100%;
   min-height: 120px;
   background-color: var(--banner-background);
   button {
     &.btn {
       padding: 5px 10px;
       font-size: 0.9rem;
       min-width: 50px;
     }
     &.logout,
     &.logout:not(:disabled):not(.disabled) {
       min-width: 20px;
       border: 0px solid transparent;
       font-size: 2em;
       background-color: inherit;
       color: var(--danger);
       box-shadow: inherit;
       &:hover,
       &:active {
         background-color: inherit;
         border: 0px solid transparent;
         color: var(--danger);
       }
       &:focus,
       &:focus-visible {
         border: 0px solid transparent;
         box-shadow: inherit;
         color: var(--danger);
         outline-width: 0;
       }
     }
   }

   a.nav-link {
     &:focus-visible {
       outline-width: 0;
     }
   }

   .navbar-collapse.show {
     background-color: var(--banner-background);
     height: auto;
     box-shadow: var(--cpax-box-shadow);
     border-radius: 7px;
     width: 100%;
     .btn {
       min-width: 200px;
     }
   }

   img.logo {
     width: 200px;
   }

   .nav-item {
     margin: auto;
   }

 }
</style>
