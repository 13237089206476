<template>
  <b-container fluid class="cpax-notification-profile-listing">
    <b-row v-for="profile in profiles" :key="profile.Name">
      <b-col>
        <c-notification-profile-listing-item :name="profile.Name"
             :enabled="profile.Enable || false"
             @view="view(profile.Name)"
             @disable="disable(profile.Name)" />
      </b-col>
    </b-row>
    <b-row>
      <b-col class="button-center">
        <b-button variant="outline-primary"
                  @click="customDialogNamePrompt" >
          + {{ T('Create Custom Profile') }}
        </b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import cNotificationProfileListingItem from "./c-notification-profile-listing-item";

export default {
  components: {
    cNotificationProfileListingItem,
  },
  props: {
    profiles: {
      type: [],
      required: true,
    }
  },
  methods: {
    view(item) {
      this.$emit('view', item)
    },
    disable(item) {
      this.$emit('disable', item);
    },
    customDialogNamePrompt() {
      const h = this.$createElement;
      const input = h('b-form-input', {
        props: {
          placeholder: this.T('Profile name'),
        }
      })
      let _vm = this;
      this.confirmationDialog(this.T('New Profile label'), [input])
          .then(value => {
            const name = input.componentInstance.localValue;
            if (value)
              _vm.createNewCustomProfile(name);

          });
    },
    createNewCustomProfile(name) {
      this.$emit('create-new-profile', name);
    }
  }
}
</script>

<style lang="scss" >
 .cpax-notification-profile-listing {
   .button-center {
     padding: 10px;
     text-align: center;
   }
   button.btn-outline-primary,
   button.btn-outline-primary:not(:disabled) {
     color: var(--primary);
     padding: 10px;
   }
 }
</style>
