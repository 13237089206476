<template>
  <c-simple-dialog :id="id"
                   dialog-class="cpax-report-generator-dialog"
                   :title="T('Adherence Report Generator')"
                   :ok-title="T('Generate Report')"
                   @ok="onGenerate">
    <b-container class="cpax-report-generator-dialog">
      <b-row class="form-row">
        <b-col>
          <span class="label">{{T('Schedule')}}</span>
        </b-col>
        <b-col>
          <b-form-select id="schedule-list"
                         :options="schedules"
                         v-model="selected">
          </b-form-select>
        </b-col>
      </b-row>
      <b-row class="form-row">
        <b-col>
          <span class="label">{{T('Start Date')}}</span>
        </b-col>
        <b-col>
          <c-datepicker id="report-start"
                        v-model="v$.start.$model"
                        :state="vuelidateState(v$.start)" />
        </b-col>
      </b-row>
      <b-row class="form-row">
        <b-col>
          <span class="label">{{T('End Date')}}</span>
        </b-col>
        <b-col>
          <c-datepicker id="report-end"
                        v-model="v$.end.$model"
                        :state="vuelidateState(v$.end)" />
        </b-col>
      </b-row>
    </b-container>
  </c-simple-dialog>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { vuelidateState } from "@/lib/vuelidate-helper";
import { required } from "@vuelidate/validators";

import cSimpleDialog from "./c-simple-dialog";
import cDatepicker from "./c-datepicker";

import moment from 'moment-timezone';

import api  from '@/api';

export default {
  components: {
    cSimpleDialog,
    cDatepicker,
  },
  setup() {
    return {
      v$: useVuelidate(),
      vuelidateState,
    };
  },
  props: {
    id: {
      required: true,
    },
    dataset: {
      type: Array,
      required: true,
    }
  },
  data() {
    const now = moment(new Date()).toDate();
    return {
      selected: "All",
      start: now,
      end: now,
    }
  },
  validations() {
    return {
      selected: {
        required,
      },
      start: {
        required,
      },
      end: {
        required,
      },
    }
  },
  computed: {
    schedules() {
      if (this.dataset) {
        return [{ text: "All", value: "All" }, ...this.dataset.map(s => Object.assign({ text: s.Description, value: s.ID }))]
      }
      return []
    }
  },
  methods: {
    onGenerate() {
      const patient = this.$store.state.patients.selectedPatient;
      console.log(patient)
      if (patient) {
        api.downloadPharmacistReport(patient.ID, this.start, this.end).then((v) => {
          console.log(v);
        });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form-row {
  margin-bottom: 20px;
}
</style>

<style lang="scss">
.cpax-report-generator-dialog {
  .modal-footer {
    justify-content: space-between;
  }
}
</style>
