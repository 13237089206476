<template>
    <b-form-input :id="id" type="date" v-model="textDate" :state="state" @change="onChanged" />
</template>

<script>
export default {
    props: {
        id: {
            type: String,
            default: 'cpax-input-datepicker',
        },
        value: {
            type: Date,
            required: true,
        },
        state: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            textDate: this.value.format("YYYY-MM-DD"),
        };
    },
    methods: {
        onChanged() {
            const d = new Date(this.textDate);
            this.$emit('input', d)
            this.$emit('change', d)
        }
    },
}
</script>
